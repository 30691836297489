import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CreateClinicForm from '@src/components/forms/createClinic.form';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const url = `${process.env.REACT_APP_API_URL}/admin/clinics`;

export default function ClinicDetail({ createClinic, setCreateClinic }) {
  const { clinicId } = useParams();
  const { auth } = useAuth();

  const [success, setSuccess] = React.useState(false);

  const handleUpdateModal = () => {
    setSuccess(true);
  };

  const handleUpdatesModal = () => {
    setSuccess(false);
  };

  const [groupData, setGroupData] = useState({});

  const [updateClinic, setUpdateClinic] = useState({
    group: '',
    clinicName: '',
    description: '',
    type: '',
    logo: '',
    location: '',
    country: '',
    corporateEmail: '',
    companyTel: '',
    clinicAddress: '',
    cms: '',
    initials: '',
  });

  function updateClinics() {
    const formData = {
      group: groupData._id,
      clinicName: createClinic.clinicName,
      description: createClinic.description,
      type: createClinic.type,
      logo: createClinic.logo,
      location: createClinic.location,
      country: createClinic.country,
      corporateEmail: createClinic.corporateEmail,
      companyTel: createClinic.companyTel,
      clinicAddress: createClinic.clinicAddress,
      cms: createClinic.cms,
      initials: createClinic.initials,
    };

    axios
      .patch(`${url}/${clinicId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account created successfully:', response.data);
        setUpdateClinic({ ...createClinic });
        setSuccess(true);
      })
      .catch((error) => {
        console.log('Error', error.message);
      });
    // console.log(formData);
  }

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
      <div className='w-2/3 px-8'>
        <Box
          sx={{
            width: '100%',
            height: '60px',
            fontFamily: 'Poppins',
          }}
          className='flex justify-end py-2'
        >
          <Button
            sx={{
              textTransform: 'none',
              width: '200px',
              height: '48px',
            }}
            className='text-md rounded-xl bg-cyan-600 font-bold text-white'
            variant='contained'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              updateClinics();
              handleUpdateModal();
            }}
          >
            Update
          </Button>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            background: 'white',
            fontFamily: 'Poppins',
          }}
          className='rounded-xl py-4'
        >
          <CreateClinicForm
            createClinic={createClinic}
            setCreateClinic={setCreateClinic}
            updateClinic={updateClinic}
            setUpdateClinic={setUpdateClinic}
          />
        </Box>
      </div>

      <Dialog open={success} onClose={handleUpdatesModal}>
        <DialogTitle>Update Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The clinic has been updated successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdatesModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
