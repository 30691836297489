/* eslint-disable no-lone-blocks */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AddMobCampaignForm from '@src/components/forms/addMobBanner.form';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/admin/mobile-banners`;
const urls = `${process.env.REACT_APP_API_URL}/event/get-event`;

export default function UpdateMobCampaign() {
  const { auth } = useAuth();
  const { adsId } = useParams();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState([]);
  const [previewUrl, setPreviewUrl] = useState([]);

  const [inputAds, setInputAds] = useState({
    file: [],
    _id: [],
    banner_type: [''],
    ads_link: [''],
    event_id: [''],
    event_link: [''],
  });

  const [updateAds, setUpdateAds] = useState({
    ads_title: '',
    file: [],
    file_id: [],
    ads_link: [''],
    event_id: [''],
    event_link: [''],
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: '',
    ads_status: ['ongoing', 'coming_soon', 'expired'],
    ads_type: '',
    banner_type: [''],
    sorts: 0,
  });

  const handleFileChange = (event, index) => {
    const selectedImages = Array.from(event.target.files);

    setInputAds((prevInput) => ({
      ...prevInput,
      file: prevInput.file.map((file, i) =>
        i === index ? { ...file, file: selectedImages[0] } : file,
      ),
    }));

    const updatedPreviewUrl = [...previewUrl];
    updatedPreviewUrl[index] = URL.createObjectURL(selectedImages[0]);
    setPreviewUrl(updatedPreviewUrl);
  };

  const handleAdsLinkChange = (index, value) => {
    setInputAds((prevInputAds) => {
      const updatedFileArray = [...prevInputAds.file];
      if (updatedFileArray[index]) {
        updatedFileArray[index] = {
          ...updatedFileArray[index],
          ads_link: value,
        };
      }
      return { ...prevInputAds, file: updatedFileArray };
    });
  };

  const handleEventLinkChange = (index, value) => {
    setInputAds((prevInputAds) => {
      const updatedFileArray = [...prevInputAds.file];
      if (updatedFileArray[index]) {
        updatedFileArray[index] = {
          ...updatedFileArray[index],
          event_link: value,
        };
      }
      return { ...prevInputAds, file: updatedFileArray };
    });
  };

  // const handleDelete = () => {
  //   setSelectedFile(null);
  //   setPreviewUrl([]);
  // };

  const [listEvents, setListEvents] = useState([]);
  const [status, setStatus] = useState('listed');
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${urls}?event_status=${status}`, {
          headers: {
            Authorization: `Bearer ${auth?.idToken}`,
          },
        });

        setListEvents(response.data);
      } catch (error) {
        console.log('Error', error.message);
      }
    };
    if (auth?.idToken) {
      fetchData();
    }
  }, [auth]);

  useEffect(() => {
    axios
      .get(`${url}/${adsId}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setInputAds(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, adsId]);

  const [open, setOpen] = React.useState(false);

  function updateMobBanner() {
    const uploadFormData = new FormData();
    uploadFormData.append('ads_title', inputAds?.ads_title);
    inputAds.file.forEach((file, index) => {
      uploadFormData.append('files', file.file);

      if (file._id) {
        uploadFormData.append(`file_id[${index}]`, file._id);
      }
      if (file.ads_link) {
        uploadFormData.append(`ads_link[${index}]`, file.ads_link);
      }
      if (file.event_link) {
        uploadFormData.append(`event_link[${index}]`, file.event_link);
      }
      if (file.event_id) {
        uploadFormData.append(`event_id[${index}]`, file.event_id);
      }
      if (file.banner_type) {
        uploadFormData.append(`banner_type[${index}]`, file.banner_type);
      }
    });
    uploadFormData.append('start_date', inputAds.start_dates);
    uploadFormData.append('end_date', inputAds.end_dates);
    uploadFormData.append('start_time', inputAds.start_times);
    uploadFormData.append('end_time', inputAds.end_times);
    uploadFormData.append('ads_status', inputAds.ads_status);
    uploadFormData.append('ads_type', inputAds.ads_type);
    uploadFormData.append('sorts', inputAds.sorts);
    // uploadFormData.append('banner_type', inputAds.banner_type);

    axios
      .put(`${url}/${adsId}`, uploadFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        const updatedInputAds = { ...inputAds };
        if (Array.isArray(response.data)) {
          response.data.forEach((savedFile, index) => {
            updatedInputAds.file[index]._id = savedFile._id;
          });
        } else {
          console.error('Response data is not an array:', response.data);
        }
        setInputAds(updatedInputAds);
        // setOpen(true);
        toast.success('Banner updated successfully', {
          position: 'top-center',
          autoClose: '1000',
        });
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: '2000',
        });
        console.log('Error', error.message);
      });
  }

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    navigate('/announcement-marketing');
  };

  const handleBack = () => {
    navigate('/announcement-marketing');
  };

  return (
    <div className='py-8 px-8'>
      <Box sx={{ width: '100%' }}>
        <div className='flex w-3/4 justify-between'>
          <Button
            sx={{
              textTransform: 'none',
              paddingLeft: 0,
            }}
            className='py-6 px-6 font-bold text-purple-500'
            variant='text'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              margin: 1,
              height: '50px',
              width: '150px',
            }}
            className='rounded-md border border-cyan-600 px-6 font-bold text-cyan-600'
            variant='outlined'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              updateMobBanner();
              // handleOpenModal();
            }}
            onNavigate={() => {
              navigate('/announcement-marketing');
            }}
          >
            Save
          </Button>
        </div>
      </Box>
      <br />
      <Box
        className='w-3/4 rounded-xl px-4'
        sx={{ width: '100%', height: '170px', backgroundColor: 'white' }}
      >
        <AddMobCampaignForm
          inputAds={inputAds}
          setInputAds={setInputAds}
          updateAds={updateAds}
          setUpdateAds={setUpdateAds}
        />
      </Box>

      <div className='py-2 px-4'>
        <div className='py-2 text-sm font-bold text-gray-500'>
          Home Screen Carousel Banner
        </div>
        <div className='text-xs text-gray-400'>(Best size 358 x 198)</div>
      </div>
      <br />
      <Box
        sx={{
          width: 'auto',
          backgroundColor: 'white',
          overflowX: 'auto',
          overflowY: 'hidden',
          scrollbarWidth: 'thin',
          scrollbarColor: 'gray',
        }}
        className='w-3/4 rounded-xl py-4 px-4'
      >
        <div className='flex' style={{ width: 'calc(408px * 4)' }}>
          <Box
            sx={{
              width: '390px',
              height: 'auto',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '358px',
                height: '200px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='flex justify-center bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 0)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input1'
                />

                {selectedFile || previewUrl || inputAds?.file[0]?.file ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[0] || inputAds?.file[0]?.file}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                ) : null}
                <label htmlFor='upload-file-input1'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Replace
                  </Button>
                  <br />
                </label>
              </div>
            </Box>
            <div className='col-span-4 px-4'>
              <label className='block py-2 text-sm font-medium leading-6 text-gray-600'>
                Banner type*
              </label>

              <FormControl>
                <Select
                  id='type'
                  style={{ height: '48px', width: '360px', fontSize: 14 }}
                  onChange={(e) => {
                    if (inputAds) {
                      setInputAds({
                        ...inputAds,
                        banner_type: e.target.value,
                      });
                    }
                  }}
                  className='rounded-md text-gray-600'
                  value={
                    inputAds?.banner_type ||
                    inputAds?.file[0]?.banner_type ||
                    ''
                  }
                >
                  <MenuItem disabled value=''>
                    <em style={{ color: '#8C8C8C' }}>Select type</em>
                  </MenuItem>

                  <MenuItem value='ads'>Ads</MenuItem>
                  <MenuItem value='event'>Event</MenuItem>
                </Select>
              </FormControl>

              {inputAds?.banner_type === 'ads' ||
                (inputAds?.file[0]?.banner_type === 'ads' && (
                  <div className='mt-2 py-4'>
                    <input
                      id='ads'
                      placeholder='Enter link'
                      style={{ width: '345px' }}
                      value={
                        (inputAds?.file[0]?.ads_link &&
                          inputAds?.file[0]?.ads_link) ||
                        inputAds?.ads_link
                      }
                      onChange={(e) => handleAdsLinkChange(0, e.target.value)}
                      className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                ))}

              {inputAds?.banner_type === 'event' ||
                (inputAds?.file[0]?.banner_type === 'event' && (
                  <>
                    <div className='py-6'>
                      <FormControl>
                        <Select
                          id='event'
                          style={{
                            height: '48px',
                            width: '360px',
                            fontSize: 14,
                          }}
                          onChange={(e) => {
                            if (inputAds) {
                              setInputAds({
                                ...inputAds,
                                event_id: e.target.value,
                              });
                            }
                          }}
                          value={
                            inputAds?.file[0]?.event_id || inputAds?.event_id
                          }
                          className='rounded-md text-gray-600'
                        >
                          <MenuItem disabled value=''>
                            <em style={{ color: '#8C8C8C' }}>Select event</em>
                          </MenuItem>
                          {listEvents?.map((option) => (
                            <MenuItem key={option.value} value={option._id}>
                              {option.event_title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <input
                        id='event'
                        placeholder='Enter link'
                        value={
                          (inputAds?.file[0]?.event_link &&
                            inputAds?.file[0]?.event_link) ||
                          inputAds?.event_link
                        }
                        onChange={(e) =>
                          handleEventLinkChange(0, e.target.value)
                        }
                        style={{ width: '345px' }}
                        className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                    </div>
                  </>
                ))}
            </div>
          </Box>

          <Box
            sx={{
              width: '390px',
              height: 'auto',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '358px',
                height: '200px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='flex justify-center bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 1)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input2'
                />

                {selectedFile || previewUrl || inputAds?.file[1]?.file ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[1] || inputAds?.file[1]?.file}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                ) : null}
                <label htmlFor='upload-file-input2'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Replace
                  </Button>
                  <br />
                </label>
              </div>
            </Box>
            <div className='col-span-4 px-4'>
              <label className='block py-2 text-sm font-medium leading-6 text-gray-600'>
                Banner type*
              </label>

              <FormControl>
                <Select
                  id='type'
                  style={{ height: '48px', width: '360px', fontSize: 14 }}
                  onChange={(e) => {
                    if (inputAds) {
                      setInputAds({
                        ...inputAds,
                        banner_type: e.target.value,
                      });
                    }
                  }}
                  className='rounded-md text-gray-600'
                  value={
                    inputAds?.banner_type ||
                    inputAds?.file[1]?.banner_type ||
                    ''
                  }
                >
                  <MenuItem disabled value=''>
                    <em style={{ color: '#8C8C8C' }}>Select type</em>
                  </MenuItem>

                  <MenuItem value='ads'>Ads</MenuItem>
                  <MenuItem value='event'>Event</MenuItem>
                </Select>
              </FormControl>

              {inputAds?.banner_type === 'ads' ||
                (inputAds?.file[1]?.banner_type === 'ads' && (
                  <div className='mt-2 py-4'>
                    <input
                      id='ads'
                      placeholder='Enter link'
                      style={{ width: '345px' }}
                      value={
                        (inputAds?.file[1]?.ads_link &&
                          inputAds?.file[1]?.ads_link) ||
                        inputAds?.ads_link
                      }
                      onChange={(e) => handleAdsLinkChange(1, e.target.value)}
                      className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                ))}

              {inputAds?.banner_type === 'event' ||
                (inputAds?.file[1]?.banner_type === 'event' && (
                  <>
                    <div className='py-6'>
                      <FormControl>
                        <Select
                          id='event'
                          style={{
                            height: '48px',
                            width: '360px',
                            fontSize: 14,
                          }}
                          onChange={(e) => {
                            if (inputAds) {
                              setInputAds({
                                ...inputAds,
                                event_id: e.target.value,
                              });
                            }
                          }}
                          value={
                            inputAds?.file[1]?.event_id || inputAds?.event_id
                          }
                          className='rounded-md text-gray-600'
                        >
                          <MenuItem disabled value=''>
                            <em style={{ color: '#8C8C8C' }}>Select event</em>
                          </MenuItem>
                          {listEvents?.map((option) => (
                            <MenuItem key={option.value} value={option._id}>
                              {option.event_title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <input
                        id='event'
                        placeholder='Enter link'
                        value={
                          (inputAds?.file[1]?.event_link &&
                            inputAds?.file[1]?.event_link) ||
                          ''
                        }
                        onChange={(e) =>
                          handleEventLinkChange(1, e.target.value)
                        }
                        style={{ width: '345px' }}
                        className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                    </div>
                  </>
                ))}
            </div>
          </Box>

          <Box
            sx={{
              width: '390px',
              height: 'auto',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '358px',
                height: '200px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='flex justify-center bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 2)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input3'
                />

                {selectedFile || previewUrl || inputAds?.file[2]?.file ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[2] || inputAds?.file[2]?.file}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                ) : null}
                <label htmlFor='upload-file-input3'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Replace
                  </Button>
                  <br />
                </label>
              </div>
            </Box>
            <div className='col-span-4 px-4'>
              <label className='block py-2 text-sm font-medium leading-6 text-gray-600'>
                Banner type*
              </label>

              <FormControl>
                <Select
                  id='type'
                  style={{ height: '48px', width: '360px', fontSize: 14 }}
                  onChange={(e) => {
                    if (inputAds) {
                      setInputAds({
                        ...inputAds,
                        banner_type: e.target.value,
                      });
                    }
                  }}
                  className='rounded-md text-gray-600'
                  value={
                    inputAds?.banner_type ||
                    inputAds?.file[2]?.banner_type ||
                    ''
                  }
                >
                  <MenuItem disabled value=''>
                    <em style={{ color: '#8C8C8C' }}>Select type</em>
                  </MenuItem>

                  <MenuItem value='ads'>Ads</MenuItem>
                  <MenuItem value='event'>Event</MenuItem>
                </Select>
              </FormControl>

              {inputAds?.banner_type === 'ads' ||
                (inputAds?.file[2]?.banner_type === 'ads' && (
                  <div className='mt-2 py-4'>
                    <input
                      id='ads'
                      placeholder='Enter link'
                      style={{ width: '345px' }}
                      value={
                        (inputAds?.file[2]?.ads_link &&
                          inputAds?.file[2]?.ads_link) ||
                        inputAds?.ads_link
                      }
                      onChange={(e) => handleAdsLinkChange(2, e.target.value)}
                      className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                ))}

              {inputAds?.banner_type === 'event' ||
                (inputAds?.file[2]?.banner_type === 'event' && (
                  <>
                    <div className='py-6'>
                      <FormControl>
                        <Select
                          id='event'
                          style={{
                            height: '48px',
                            width: '360px',
                            fontSize: 14,
                          }}
                          onChange={(e) => {
                            if (inputAds) {
                              setInputAds({
                                ...inputAds,
                                event_id: e.target.value,
                              });
                            }
                          }}
                          value={
                            inputAds?.file[2]?.event_id || inputAds?.event_id
                          }
                          className='rounded-md text-gray-600'
                        >
                          <MenuItem disabled value=''>
                            <em style={{ color: '#8C8C8C' }}>Select event</em>
                          </MenuItem>
                          {listEvents?.map((option) => (
                            <MenuItem key={option.value} value={option._id}>
                              {option.event_title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <input
                        id='event'
                        placeholder='Enter link'
                        value={
                          (inputAds?.file[2]?.event_link &&
                            inputAds?.file[2]?.event_link) ||
                          ''
                        }
                        onChange={(e) =>
                          handleEventLinkChange(2, e.target.value)
                        }
                        style={{ width: '345px' }}
                        className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                    </div>
                  </>
                ))}
            </div>
          </Box>

          <Box
            sx={{
              width: '390px',
              height: 'auto',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '358px',
                height: '200px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='flex justify-center bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 3)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input4'
                />

                {selectedFile || previewUrl || inputAds?.file[3]?.file ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[3] || inputAds?.file[3]?.file}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                ) : null}
                <label htmlFor='upload-file-input4'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Replace
                  </Button>
                  <br />
                </label>
              </div>
            </Box>
            <div className='col-span-4 px-4'>
              <label className='block py-2 text-sm font-medium leading-6 text-gray-600'>
                Banner type*
              </label>

              <FormControl>
                <Select
                  id='type'
                  style={{ height: '48px', width: '360px', fontSize: 14 }}
                  onChange={(e) => {
                    if (inputAds) {
                      setInputAds({
                        ...inputAds,
                        banner_type: e.target.value,
                      });
                    }
                  }}
                  className='rounded-md text-gray-600'
                  value={
                    inputAds?.banner_type ||
                    inputAds?.file[3]?.banner_type ||
                    ''
                  }
                >
                  <MenuItem disabled value=''>
                    <em style={{ color: '#8C8C8C' }}>Select type</em>
                  </MenuItem>

                  <MenuItem value='ads'>Ads</MenuItem>
                  <MenuItem value='event'>Event</MenuItem>
                </Select>
              </FormControl>

              {inputAds?.banner_type === 'ads' ||
                (inputAds?.file[3]?.banner_type === 'ads' && (
                  <div className='mt-2 py-4'>
                    <input
                      id='ads'
                      placeholder='Enter link'
                      style={{ width: '345px' }}
                      value={
                        (inputAds?.file[3]?.ads_link &&
                          inputAds?.file[3]?.ads_link) ||
                        inputAds?.ads_link
                      }
                      onChange={(e) => handleAdsLinkChange(3, e.target.value)}
                      className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                ))}

              {inputAds?.banner_type === 'event' ||
                (inputAds?.file[3]?.banner_type === 'event' && (
                  <>
                    <div className='py-6'>
                      <FormControl>
                        <Select
                          id='event'
                          style={{
                            height: '48px',
                            width: '360px',
                            fontSize: 14,
                          }}
                          onChange={(e) => {
                            if (inputAds) {
                              setInputAds({
                                ...inputAds,
                                event_id: e.target.value,
                              });
                            }
                          }}
                          value={
                            inputAds?.file[3]?.event_id || inputAds?.event_id
                          }
                          className='rounded-md text-gray-600'
                        >
                          <MenuItem disabled value=''>
                            <em style={{ color: '#8C8C8C' }}>Select event</em>
                          </MenuItem>
                          {listEvents?.map((option) => (
                            <MenuItem key={option.value} value={option._id}>
                              {option.event_title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <input
                        id='event'
                        placeholder='Enter link'
                        value={
                          (inputAds?.file[3]?.event_link &&
                            inputAds?.file[3]?.event_link) ||
                          ''
                        }
                        onChange={(e) =>
                          handleEventLinkChange(3, e.target.value)
                        }
                        style={{ width: '345px' }}
                        className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                    </div>
                  </>
                ))}
            </div>
          </Box>
        </div>
        <br />
        <div className='px-6 text-xs text-gray-300'>*image is required</div>
      </Box>

      <br />
      <br />
      <br />
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Save Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The ads has been updated successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
