import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddNewCampaignForm from '@src/components/forms/addWebCampaign.form';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/admin/web-banners`;

export default function UpdateAdminAdvert() {
  const { auth } = useAuth();
  const { bannerId } = useParams();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState([]);

  const [input, setInput] = useState({
    file: [],
    _id: [],
    campaign_link: [''],
  });
  const [updates, setUpdates] = useState({
    campaign_title: '',
    campaign_link: [],
    files: [],
    file_id: [],
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: '',
    campaign_status: ['ongoing', 'coming_soon', 'pause'],
    sorts: '',
  });

  const handleFileChange = (event, index) => {
    const selectedImages = Array.from(event.target.files);

    setInput((prevInput) => ({
      ...prevInput,
      file: prevInput.file.map((file, i) =>
        i === index ? { ...file, file: selectedImages[0] } : file,
      ),
    }));

    const updatedPreviewUrl = [...previewUrl];
    updatedPreviewUrl[index] = URL.createObjectURL(selectedImages[0]);
    setPreviewUrl(updatedPreviewUrl);
  };

  const handleLinkChange = (index, value) => {
    setInput((prevInput) => {
      const updatedFileArray = [...prevInput.file];
      if (updatedFileArray[index]) {
        updatedFileArray[index] = {
          ...updatedFileArray[index],
          campaign_link: value,
        };
      }
      return { ...prevInput, file: updatedFileArray };
    });
  };

  // const handleDelete = () => {
  //   setSelectedFile(null);
  //   setPreviewUrl([]);
  // };

  useEffect(() => {
    axios
      .get(`${url}/${bannerId}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setInput(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [auth, bannerId]);

  function updateWebBanner() {
    const uploadFormData = new FormData();
    uploadFormData.append('campaign_title', input?.campaign_title);
    input.file.forEach((file, index) => {
      uploadFormData.append('files', file.file);
      if (file._id) {
        uploadFormData.append(`file_id[${index}]`, file._id);
      }
      if (file.campaign_link) {
        uploadFormData.append(`campaign_link[${index}]`, file.campaign_link);
      }
    });
    uploadFormData.append('start_date', input.start_dates);
    uploadFormData.append('end_date', input.end_dates);
    uploadFormData.append('start_time', input.start_times);
    uploadFormData.append('end_time', input.end_times);
    uploadFormData.append('campaign_status', input.campaign_status);
    uploadFormData.append('sorts', input.sorts);

    axios
      .put(`${url}/${bannerId}`, uploadFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        const updatedInput = { ...input };
        if (Array.isArray(response.data)) {
          response.data.forEach((savedFile, index) => {
            updatedInput.file[index]._id = savedFile._id;
          });
        } else {
          console.error('Response data is not an array:', response.data);
        }
        setInput(updatedInput);
        toast.success('Campaign updated successfully', {
          position: 'top-center',
          autoClose: '1000',
        });
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: '2000',
        });
      });
  }

  const handleBack = () => {
    navigate('/announcement-marketing');
  };
  return (
    <div className='py-8 px-8'>
      <Box sx={{ width: '100%' }}>
        <div className='flex w-3/4 justify-between'>
          <Button
            sx={{
              textTransform: 'none',
              paddingLeft: 0,
            }}
            className='py-6 px-6 font-bold text-purple-500'
            variant='text'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              margin: 1,
              height: '50px',
              width: '150px',
            }}
            className='rounded-md border border-cyan-600 px-6 font-bold text-cyan-600'
            variant='outlined'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={updateWebBanner}
            onNavigate={() => {
              navigate('/announcement-marketing');
            }}
          >
            Save
          </Button>
        </div>
      </Box>
      <br />
      <Box
        className='w-3/4 rounded-xl py-4 px-4'
        sx={{ width: '100%', height: '151px', backgroundColor: 'white' }}
      >
        <AddNewCampaignForm
          input={input}
          setInput={setInput}
          updates={updates}
          setUpdates={setUpdates}
        />
      </Box>

      <div className='py-4 px-4'>
        <div className='py-2 text-sm font-bold text-gray-500'>
          Horizontal Desktop Screen Admin
        </div>
        <div className='text-xs text-gray-400'>(Best size 938px by 198px)</div>
      </div>
      <br />
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'white',
          overflowX: 'auto',
          overflowY: 'hidden',
          scrollbarWidth: 'thin',
          scrollbarColor: 'gray',
        }}
        className='w-3/4 rounded-xl py-4 px-4'
      >
        <div className='flex' style={{ width: 'calc(695px * 5)' }}>
          <Box
            sx={{
              margin: 2,
              width: '695px',
              height: '296px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 0)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input2'
                />

                {selectedFile || previewUrl || input?.file[0]?.file ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[0] || input?.file[0]?.file}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                ) : null}
                <label htmlFor='upload-file-input2'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                  {/* <Button
                  sx={{
                    textTransform: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '70%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                  variant="contained"
                  component="span"
                  className="bg-gray-400 font-bold text-xs underline"
                  onClick={handleDelete}
                >
                  Remove
                </Button> */}
                </label>
              </div>
            </Box>
            <div className='col-span-4  px-4'>
              <label className='block text-sm font-medium leading-6 text-gray-600'>
                Campaign Link
              </label>
              <div className='mt-2'>
                <input
                  placeholder='Enter Link'
                  value={
                    (input?.file[0]?.campaign_link &&
                      input?.file[0]?.campaign_link) ||
                    input?.campaign_link
                  }
                  onChange={(e) => handleLinkChange(0, e.target.value)}
                  className='font-poppins block w-full rounded-md border-0 bg-white py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
          </Box>

          <Box
            sx={{
              margin: 2,
              width: '695px',
              height: '296px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 1)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input'
                />

                {selectedFile || previewUrl || input?.file[1]?.file ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[1] || input?.file[1]?.file}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                ) : null}
                <label htmlFor='upload-file-input'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                  {/* <Button
                  sx={{
                    textTransform: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '70%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                  variant='contained'
                  component='span'
                  className='bg-gray-400 text-xs font-bold underline'
                  onClick={handleDelete}
                >
                  Remove
                </Button> */}
                </label>
              </div>
            </Box>
            <div className='col-span-4  px-4'>
              <label className='block text-sm font-medium leading-6 text-gray-600'>
                Campaign Link
              </label>
              <div className='mt-2'>
                <input
                  placeholder='Enter Link'
                  value={
                    (input?.file[1]?.campaign_link &&
                      input?.file[1]?.campaign_link) ||
                    input?.campaign_link
                  }
                  onChange={(e) => handleLinkChange(1, e.target.value)}
                  className='font-poppins block w-full rounded-md border-0 bg-white py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
          </Box>

          <Box
            sx={{
              margin: 2,
              width: '695px',
              height: '296px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 2)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input3'
                />

                {selectedFile || previewUrl || input?.file[2]?.file ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[2] || input?.file[2]?.file}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                ) : null}
                <label htmlFor='upload-file-input3'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                  {/* <Button
                  sx={{
                    textTransform: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '70%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                  variant="contained"
                  component="span"
                  className="bg-gray-400 font-bold text-xs underline"
                  onClick={handleDelete}
                >
                  Remove
                </Button> */}
                </label>
              </div>
            </Box>
            <div className='col-span-4  px-4'>
              <label className='block text-sm font-medium leading-6 text-gray-600'>
                Campaign Link
              </label>
              <div className='mt-2'>
                <input
                  placeholder='Enter Link'
                  value={
                    (input?.file[2]?.campaign_link &&
                      input?.file[2]?.campaign_link) ||
                    input?.campaign_link
                  }
                  onChange={(e) => handleLinkChange(2, e.target.value)}
                  className='font-poppins block w-full rounded-md border-0 bg-white py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
          </Box>

          <Box
            sx={{
              margin: 2,
              width: '695px',
              height: '296px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 3)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input4'
                />

                {selectedFile || previewUrl || input?.file[3]?.file ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[3] || input?.file[3]?.file}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                ) : null}
                <label htmlFor='upload-file-input4'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                  {/* <Button
                  sx={{
                    textTransform: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '70%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                  variant="contained"
                  component="span"
                  className="bg-gray-400 font-bold text-xs underline"
                  onClick={handleDelete}
                >
                  Remove
                </Button> */}
                </label>
              </div>
            </Box>
            <div className='col-span-4  px-4'>
              <label className='block text-sm font-medium leading-6 text-gray-600'>
                Campaign Link
              </label>
              <div className='mt-2'>
                <input
                  placeholder='Enter Link'
                  value={
                    (input?.file[3]?.campaign_link &&
                      input?.file[3]?.campaign_link) ||
                    input?.campaign_link
                  }
                  onChange={(e) => handleLinkChange(3, e.target.value)}
                  className='font-poppins block w-full rounded-md border-0 bg-white py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
          </Box>

          <Box
            sx={{
              margin: 2,
              width: '695px',
              height: '296px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                margin: 2,
                width: '635px',
                height: '135px',
                position: 'relative',
                overflow: 'hidden',
              }}
              className='bg-gray-300'
            >
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, 4)}
                  name='files'
                  multiple
                  style={{ display: 'none' }}
                  id='upload-file-input5'
                />

                {selectedFile || previewUrl || input?.file[4]?.file ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={previewUrl[4] || input?.file[4]?.file}
                      alt=''
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                  </div>
                ) : null}
                <label htmlFor='upload-file-input5'>
                  <Button
                    sx={{
                      textTransform: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '30%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                    variant='contained'
                    component='span'
                    className='bg-gray-400 text-xs font-bold underline'
                  >
                    Upload
                  </Button>
                  <br />
                  {/* <Button
                  sx={{
                    textTransform: 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '70%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                  variant="contained"
                  component="span"
                  className="bg-gray-400 font-bold text-xs underline"
                  onClick={handleDelete}
                >
                  Remove
                </Button> */}
                </label>
              </div>
            </Box>
            <div className='col-span-4  px-4'>
              <label className='block text-sm font-medium leading-6 text-gray-600'>
                Campaign Link
              </label>
              <div className='mt-2'>
                <input
                  placeholder='Enter Link'
                  value={
                    (input?.file[4]?.campaign_link &&
                      input?.file[4]?.campaign_link) ||
                    input?.campaign_link
                  }
                  onChange={(e) => handleLinkChange(4, e.target.value)}
                  className='font-poppins block w-full rounded-md border-0 bg-white py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
          </Box>
        </div>

        <div className='px-6 text-xs text-gray-300'>*image is required</div>
      </Box>

      <br />
      <br />
      <br />
    </div>
  );
}
