import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const styles = {
  branchCard: {
    margin: 'auto',
    width: '264px',
    height: '370px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 16px rgba(214, 214, 214, 0.25)',
    borderRadius: '16px',
    padding: '37px 8px 13px 18px',
  },
  branchName: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    // color: '#434343',
    color: '#117DAC',
    marginTop: '4px',
    marginBottom: '11px',
  },
  address: {
    width: '100%',
    marginRight: '46px',
    marginBottom: '13px',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#8C8C8C',
  },
  appointment: {
    marginBottom: '4px',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#737373',
  },
  appointmentFont: {
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '36px',
    color: '#0EA4D6',
  },
  emptySlotsColor: {
    color: '#0EA4D6',
  },
  pendingAppointmentColor: {
    color: '#CA00CE',
  },
  filledAppointmentColor: {
    color: '#AD44FF',
  },
};

const url = `${process.env.REACT_APP_API_URL}`;

function BranchListCard() {
  const { auth } = useAuth();
  const { orgId } = useParams();
  const [listBranch, setListBranch] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const navigate = useNavigate();
  const handleClickClinic = (clinicId) => {
    navigate(`/verified-clinic/${clinicId}`);
  };

  const [loading, setLoading] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    if (auth?.idToken) {
      setLoading(true);
      axios
        .get(`${url}/dashboard/web/${orgId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setListBranch(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    }
    // console.log(listBranch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, orgId]);

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredList = listBranch.filter((row) => {
    return row?.clinicNAme.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <>
      <Box
        sx={{
          height: '80px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <input
          placeholder='Search by name'
          value={searchQuery}
          onChange={handleSearchQueryChange}
          className='font-poppins  block  rounded-xl border-0 py-3 px-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
          style={{ height: '30px', width: '250px' }}
        />
      </Box>
      <br />
      {loading ? (
        <div className='font-poppins py-6 px-8 text-gray-500'>Loading...</div>
      ) : (
        <Grid container spacing={2} justifyContent='flex-start'>
          {filteredList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <Grid item xs={12} sm={6} md={4} key={row?.clinicId}>
                <div
                  label='overview'
                  style={styles.branchCard}
                  className='w-full rounded-xl border border-gray-300'
                >
                  <Button
                    style={styles.branchName}
                    onClick={() => {
                      handleClickClinic(row?.clinicId);
                    }}
                    className='text-md mt-4 mb-8 font-medium text-blue-600'
                  >
                    {row?.clinicNAme}
                  </Button>
                  <div
                    style={styles.address}
                    className='mb-8 text-sm text-gray-700'
                  >
                    {row?.clinicAddress}
                  </div>
                  <Divider sx={{ color: '#D6D6D6' }} />
                  <div style={{ marginBottom: '11px' }} className='mb-8'>
                    <div style={styles.appointment} className='py-2'>
                      Empty Slot:
                    </div>
                    <div
                      style={{
                        ...styles.appointmentFont,
                        ...styles.emptySlotsColor,
                      }}
                    >
                      {row?.emptySlots}
                    </div>
                  </div>

                  <div>
                    <div style={styles.appointment}>Filled Slot:</div>
                    <div
                      style={{
                        ...styles.appointmentFont,
                        ...styles.filledAppointmentColor,
                      }}
                    >
                      {row?.filledSlots}
                    </div>
                  </div>
                  <div style={{ marginBottom: '11px' }}>
                    <div style={styles.appointment}>All Slots:</div>
                    <div
                      style={{
                        ...styles.appointmentFont,
                        ...styles.pendingAppointmentColor,
                      }}
                    >
                      {row?.allSlots}
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      )}
      <Box
        className='flex justify-end py-3'
        sx={{ width: '100%', fontFamily: 'Poppins' }}
      >
        <TablePagination
          rowsPerPageOptions={[15, 25, 100]}
          component='div'
          count={listBranch.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
}

export default function BranchList() {
  return (
    <>
      <Grid container spacing={1} justifyContent='left'>
        <Grid item>
          <BranchListCard />
        </Grid>
      </Grid>
    </>
  );
}
