import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import LabTabs from '@src/components/commons/tabs.common';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import AdminClinicList from './adminClinicList';
import ClinicAdditionalInfo from './clinicAdditionalInfo';
import ClinicListingPage from './clinicListing.page';
import ClinicBranchProfile from './clinicProfile.card';
import ClinicSettingPage from './clinicSetting.page';
import ClinicDetail from './detailClinic';

// eslint-disable-next-line import/default
const url = `${process.env.REACT_APP_API_URL}/admin/clinics`;

export default function ViewClinicPage() {
  const navigate = useNavigate();

  const { clinicId } = useParams();
  const { auth } = useAuth();
  const [createClinic, setCreateClinic] = useState();
  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/${clinicId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setCreateClinic(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, clinicId]);

  const categories = [
    {
      idx: 0,
      title: 'Verification Information',
      component: (
        <div className='py-4'>
          <ClinicDetail
            createClinic={createClinic}
            setCreateClinic={setCreateClinic}
          />
        </div>
      ),
    },
    {
      idx: 1,
      title: 'Profile',
      component: (
        <div>
          <ClinicBranchProfile />
        </div>
      ),
    },
    {
      idx: 2,
      title: 'Listing',
      component: (
        <div className='py-4'>
          <ClinicListingPage />
        </div>
      ),
    },
    {
      idx: 3,
      title: 'Team',
      component: (
        <div>
          <AdminClinicList />
        </div>
      ),
    },
    process.env.REACT_APP_FEATURE_CLINIC_ADDITIONAL_INFO === 'true' && {
      idx: 4,
      title: 'Additional Info',
      component: <ClinicAdditionalInfo />,
    },
    process.env.REACT_APP_FEATURE_CLINIC_SETTING === 'true' && {
      idx: 5,
      title: 'Setting',
      component: <ClinicSettingPage />,
    },
  ].filter(Boolean);

  const state = useLocation();
  const handleBack = () => {
    navigate(state?.prevLocation || -1);
  };
  return (
    <>
      <Box width='75%' display='flex' justifyContent='space-between'>
        <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-8 px-8 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleBack}
        >
          Back
        </Button>
        <Box sx={{ mt: 5, borderRadius: 8, height: '60px' }}>
          <Typography
            fontSize={14}
            paddingLeft={4}
            paddingRight={4}
            className='font-bold text-gray-400'
          >
            {createClinic?.clinicName} | {createClinic?.code}
          </Typography>
        </Box>
      </Box>
      <div className='py-6'>
        <LabTabs menus={categories} />
      </div>
    </>
  );
}
