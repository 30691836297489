/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonAction from '@src/components/buttons/action.button';
import LabTabs from '@src/components/commons/tabs.common';

import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AppUserVerificationInformation from './appUserVerification';
import UserCertificate from './doctorCertificate';
import DoctorCertificate from './doctorCertificate';
import DoctorProfile from './doctorProfile';

export default function UnverifiedDoctorInformation() {
  const path = useLocation().pathname;
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(state?.prevLocation || -1);
  };

  const menuAppUsers = [
    {
      idx: 0,
      title: 'Verification Information',
      component: (
        <div>
          <AppUserVerificationInformation />
        </div>
      ),
    },
    {
      idx: 1,
      title: 'Certificates',
      component: (
        <div>
          <DoctorCertificate />
        </div>
      ),
    },
    {
      idx: 2,
      title: 'Profile',
      component: (
        <div>
          <DoctorProfile />
        </div>
      ),
    },
  ];

  return (
    <Box
      sx={{ width: '100%', margin: 4, fontFamily: 'Poppins' }}
      pathUrl={path}
      actionRight={<ButtonAction label='Create account for users' />}
    >
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-8 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <LabTabs menus={menuAppUsers} />
    </Box>
  );
}
