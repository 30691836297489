import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}`;

export default function AddOtherCert({
  openModal,
  setOpenModal,
  fetchOtherCert,
}) {
  const { auth } = useAuth();
  const { userId } = useParams();
  const [getOtherCert, setGetOtherCert] = useState([]);

  const handleVaccineCert = () => {
    const formData = {
      certificate: getOtherCert.certificate,
      file: getOtherCert.file,
      user_id: userId,
    };
    axios
      .post(`${url}/admin/other-nurse-certificates`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Cert updated successfully:', response);
        fetchOtherCert();
        setGetOtherCert({ ...getOtherCert });
        toast.success('Cert updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFile = (e) => {
    const files = e.target.files[0];
    if (files) {
      setSelectedFile(files.name);
      setGetOtherCert((prevInput) => ({
        ...prevInput,
        file: files,
      }));
    }
  };
  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <DialogTitle>Add new other cert</DialogTitle>

      <DialogContent sx={{ mt: 2 }}>
        <div className='flex justify-between'>
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              height: '36px',
              width: '350px',
            }}
            value={getOtherCert?.certificate}
            onChange={(e) =>
              setGetOtherCert({ ...getOtherCert, certificate: e.target.value })
            }
            placeholder='e.g. Phlebotomist'
          />
          &nbsp; &nbsp; &nbsp;
          <input
            type='file'
            id='cert'
            accept='image/jpeg, image/png, image/webp, image/svg+xml, application/pdf'
            onChange={handleFile}
            className='text-md w-1/3'
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setOpenModal(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => handleVaccineCert()}
          color='primary'
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
