import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ButtonAction from '@src/components/buttons/action.button';
import AddResidenStatus from '@src/components/modals/addResidenStatus';
import EditResidenStatus from '@src/components/modals/editResidenStatus';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/lookup/master-data/residential-status`;

export default function ResidenStatus() {
  const { auth } = useAuth();

  const [loading, setLoading] = useState();
  const [residen, setResiden] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setResiden(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [deleteStatus]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${url}/${id}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
          'My-Custom-Header': 'foobar',
        },
      });

      if (response.status === 200) {
        console.log(response);
        setDeleteStatus('Delete successful');
        setShowDeleteSuccess(true);
        toast.success('Item deleted successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      } else {
        console.error('Delete operation failed');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const showModal = () => {
    setOpenModal(true);
  };

  const [openModals, setOpenModals] = useState(false);
  const showModals = (itemId) => {
    setOpenModals(itemId);
  };

  return (
    <div>
      <div className='py-8'>
        <Box
          sx={{ width: '100%', height: '60px' }}
          className='flex w-3/4 justify-end'
        >
          <ButtonAction
            onNavigate={() => {
              showModal();
            }}
            label='Add New Item'
          />
        </Box>
        {loading ? (
          <div className='font-poppins py-6 px-8 text-gray-500'>Loading...</div>
        ) : (
          <span className='flex w-3/4 flex-col items-start pr-20'>
            <TableContainer
              className='rounded-xl'
              component={Paper}
              style={{
                width: '100%',
                paddingBottom: '12px',
                paddingRight: '12px',
                backgroundColor: '#ffffff',
                borderColor: 'ffffff',
              }}
            >
              <Table
                sx={{
                  minWidth: 350,
                  backgroundColor: '#ffffff',
                  borderColor: 'ffffff',
                }}
                aria-label='simple table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{
                        width: '50px',
                        border: 'gray',
                        paddingLeft: 30,
                      }}
                    >
                      Code
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '100px', border: 'gray' }}
                    >
                      Description
                    </TableCell>

                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '150px', border: 'gray' }}
                    >
                      Label
                    </TableCell>

                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '220px', border: 'gray' }}
                    >
                      Note
                    </TableCell>
                    <TableCell
                      className='text-xs font-bold text-gray-400'
                      style={{ padding: 4, width: '100px', border: 'gray' }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(residen) && residen.length > 0 ? (
                    residen.map((row) => (
                      <TableRow
                        key={row?._id}
                        hover
                        tabIndex={-1}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component='th'
                          scope='row'
                          style={{ margin: 2, paddingLeft: 30 }}
                          className='text-sm font-bold text-gray-500'
                        >
                          {row?.code || '-'}
                        </TableCell>
                        <TableCell
                          className='text-sm font-bold text-gray-500'
                          style={{ padding: 4 }}
                        >
                          {row?.desc || '-'}
                        </TableCell>

                        <TableCell
                          className=' text-sm font-bold text-gray-500'
                          style={{ padding: 4, margin: 2 }}
                        >
                          {row?.label || '-'}
                        </TableCell>
                        <TableCell
                          className=' text-sm font-bold text-gray-500'
                          style={{ padding: 4, margin: 2 }}
                        >
                          {row?.note || '-'}
                        </TableCell>
                        <TableCell style={{ padding: 4, margin: 2 }}>
                          <div className='flex justify-start '>
                            <Button
                              variant='contained'
                              className='text-xs font-bold text-purple-600'
                              sx={{
                                textTransform: 'none',
                                backgroundColor: '#FAF9FC',
                                '&:hover': {
                                  backgroundColor: 'white',
                                },
                              }}
                              onClick={() => showModals(row?._id)}
                            >
                              Edit
                            </Button>
                            &nbsp; &nbsp;
                            <Button
                              variant='contained'
                              className='text-xs font-bold text-red-500'
                              sx={{
                                textTransform: 'none',
                                backgroundColor: '#FAF9FC',
                                '&:hover': {
                                  backgroundColor: 'white',
                                },
                              }}
                              onClick={() =>
                                showDeleteConfirmationDialog(row?._id)
                              }
                            >
                              Delete
                            </Button>
                          </div>
                          {showDeleteConfirmation && (
                            <Dialog
                              open={showDeleteConfirmation}
                              onClose={handleConfirmationDialogClose}
                            >
                              <DialogTitle>Confirm Delete</DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  Are you sure you want to delete this item?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={handleConfirmationDialogClose}
                                  color='primary'
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={handleConfirmationDialogConfirm}
                                  color='error'
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        className='border border-white'
                        colSpan={6}
                        align='center'
                      >
                        <div className='font-poppins py-6 text-gray-500'>
                          No data
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </span>
        )}
      </div>

      <AddResidenStatus
        setOpenModal={setOpenModal}
        showModal={showModal}
        openModal={openModal}
        fetchData={fetchData}
      />

      <EditResidenStatus
        setOpenModals={setOpenModals}
        showModals={showModals}
        openModals={openModals}
        fetchData={fetchData}
      />
      <br />
      <br />
      <br />
    </div>
  );
}
