import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}`;

export default function AddVaccinationCert({
  openModals,
  setOpenModals,
  fetchDataVaccine,
}) {
  const { auth } = useAuth();
  const { userId } = useParams();
  const [getVaccineCert, setGetVaccineCert] = useState([]);

  const handleVaccineCert = () => {
    const formData = {
      diphtheria: getVaccineCert.diphtheria,
      measles: getVaccineCert.measles,
      malpractice_insurance: getVaccineCert.malpractice_insurance,
    };

    axios
      .post(`${url}/admin/vaccinations/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Cert updated successfully:', response);
        setGetVaccineCert({ ...getVaccineCert });
        fetchDataVaccine();
        toast.success('Cert updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error('You have uploaded the certs. The file will not uploaded', {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFile = (e) => {
    const files = e.target.files[0];
    if (files) {
      setSelectedFile(files.name);
      setGetVaccineCert((prevInput) => ({
        ...prevInput,
        diphtheria: files,
        measles: files,
        malpractice_insurance: files,
      }));
    }
  };
  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={openModals}
      onClose={() => setOpenModals(false)}
    >
      <DialogTitle>Add new vaccination cert</DialogTitle>
      <br />

      <DialogContent>
        <div className='flex justify-between'>
          <Select
            fullWidth
            displayEmpty
            value={getVaccineCert?.certificate}
            onChange={(e) =>
              setGetVaccineCert({
                ...getVaccineCert,
                certificate: e.target.value,
              })
            }
            sx={{ mt: 1, mb: '16px', borderRadius: '8px', width: '300px' }}
            MenuProps={{
              disablePortal: true,
              PaperProps: {
                style: {
                  zIndex: 1302,
                },
              },
            }}
          >
            <MenuItem disabled value=''>
              <em style={{ color: '#8C8C8C' }}>Select</em>
            </MenuItem>
            <MenuItem value='diphtheria'>Diphtheria</MenuItem>
            <MenuItem value='measles'>Measles</MenuItem>
            <MenuItem value='malpractice_insurance'>
              Malpractice Insurance
            </MenuItem>
          </Select>
          &nbsp; &nbsp; &nbsp;
          <input
            type='file'
            id='vaccineCert'
            accept='image/jpeg, image/png, image/webp, image/svg+xml, application/pdf'
            onChange={handleFile}
            className='text-md w-1/3'
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setOpenModals(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => handleVaccineCert()}
          color='primary'
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
