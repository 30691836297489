/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';
import PropTypes from 'prop-types';

import { useEffect } from 'react';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ExclusionUserList from './exclusionUserList';
import InclusionUserList from './inclusionUserList';

const url = `${process.env.REACT_APP_API_URL}`;

function TabPanel(props) {
  const { children, value, index, style = {} } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabLabel(index) {
  return {
    id: `simple-tab-${index}`,
    poppins: `simple-tabpanel-${index}`,
  };
}

export default function EAppraisalPage() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { clinicId } = useParams();
  const { auth } = useAuth();
  const [clinic, setClinic] = useState();

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/admin/clinics/${clinicId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setClinic(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, clinicId]);

  const [inc, setInc] = useState([]);
  const [exc, setExc] = useState([]);

  const [loading, setLoading] = useState();

  const fetchDataInclusion = () => {
    setLoading(true);
    if (auth?.idToken) {
      axios
        .get(
          `${url}/admin/users/get-inclusion-by-clinic?clinic_id=${clinicId}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.idToken}`,
            },
          },
        )
        .then((response) => {
          setInc(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log('Error fetching data', error);
        });
    }
  };

  const fetchDataExclusion = () => {
    setLoading(true);
    if (auth?.idToken) {
      axios
        .get(
          `${url}/admin/users/get-exclusion-by-clinic?clinic_id=${clinicId}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.idToken}`,
            },
          },
        )
        .then((response) => {
          setExc(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log('Error fetching data', error);
        });
    }
  };

  useEffect(() => {
    fetchDataInclusion();
    fetchDataExclusion();
  }, [auth?.idToken]);

  const [addIncModal, setAddIncModal] = useState(false);
  const showAddIncModal = () => {
    setAddIncModal(true);
  };

  const [addExcModal, setAddExcModal] = useState(false);
  const showAddExcModal = () => {
    setAddExcModal(true);
  };

  const [exclusion, setExclusion] = useState();
  const [inclusion, setInclusion] = useState();

  function postInclusion() {
    const formData = {
      user_code: inclusion.user_code,
    };
    axios
      .post(`${url}/admin/users/set-inclusion/${clinic?.group}`, formData, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setInclusion(response.data);
        toast.success('The data updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
        setAddIncModal(false);
        fetchDataInclusion();
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  }

  function postExclusion() {
    const formData = {
      user_code: exclusion.user_code,
    };
    axios
      .post(`${url}/admin/users/set-exclusion/${clinic?.group}`, formData, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        setExclusion(response.data);
        toast.success('The data updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
        setAddExcModal(false);
        fetchDataExclusion();
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  }

  const navigate = useNavigate();
  const [anchorElAddNew, setAnchorElAddNew] = React.useState(null);
  const openAddNew = Boolean(anchorElAddNew);
  const handleAddNew = {
    click: (ev) => setAnchorElAddNew(ev.currentTarget),
    close: () => setAnchorElAddNew(null),
    inclusion: () => showAddIncModal(),
    exclusion: () => showAddExcModal(),
  };

  return (
    <div>
      <Box className='w-full'>
        <Box
          sx={{ width: '100%', height: '60px' }}
          className='flex w-2/3 justify-end px-24'
        >
          <div className='flex'>
            <Button
              onClick={handleAddNew.click}
              className='h-[35px] w-[100px] rounded-xl border-none bg-gradient-to-l from-cyan-500 to-cyan-600 py-0 px-4 text-xs font-semibold normal-case text-white shadow hover:shadow-cyan-500'
              id='add-new-button'
              aria-controls={openAddNew ? 'add-new-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openAddNew ? 'true' : undefined}
              disableElevation
            >
              Add New
            </Button>
            <Menu
              id='add-new'
              MenuListProps={{
                'aria-labelledby': 'add-new-button',
              }}
              anchorEl={anchorElAddNew}
              open={openAddNew}
              onClose={handleAddNew.close}
            >
              <MenuItem onClick={handleAddNew.inclusion} disableRipple>
                Inclusion
              </MenuItem>
              <MenuItem onClick={handleAddNew.exclusion} disableRipple>
                Exclusion
              </MenuItem>
            </Menu>
          </div>
        </Box>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          className=' border-gray-50'
        >
          <Tabs
            indicatorColor='white'
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            className='px-6'
          >
            <Tab
              sx={{
                textTransform: 'none',
                width: '150px',
                borderRadius: '0.375rem',
                border: '2px solid',
                borderColor: value === 0 ? '#9081D5' : 'transparent',
                backgroundColor: value === 0 ? 'white' : 'transparent',
                color: value === 0 ? '#9081D5' : '#9081D5',
                '&:hover': {
                  backgroundColor: value === 0 ? 'white' : '#FFFFFF',
                },
              }}
              label='Inclusion'
              {...tabLabel(0)}
            />

            <Box sx={{ width: '12px' }} />
            <Tab
              sx={{
                textTransform: 'none',
                width: '150px',
                borderRadius: '0.375rem',
                border: '2px solid',
                borderColor: value === 2 ? '#9081D5' : 'transparent',
                backgroundColor: value === 2 ? 'white' : 'transparent',
                color: value === 2 ? '#9081D5' : '#9081D5',
                '&:hover': {
                  backgroundColor: value === 2 ? 'white' : '#FFFFFF',
                },
              }}
              label='Exclusion'
              {...tabLabel(0)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <InclusionUserList
            clinic={clinic}
            inc={inc}
            loading={loading}
            fetchDataInclusion={fetchDataInclusion}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ExclusionUserList
            clinic={clinic}
            exc={exc}
            loading={loading}
            fetchDataExclusion={fetchDataExclusion}
          />
        </TabPanel>
      </Box>

      <Dialog
        maxWidth='lg'
        fullwidth
        open={addIncModal}
        onClose={() => setAddIncModal(false)}
      >
        <DialogTitle>Add new inclusion</DialogTitle>

        <DialogContent>
          <div className='flex justify-between'>
            <br />

            <Box flex={1} mr={1}>
              <>
                <Typography fontFamily='Poppins'>Locum ID</Typography>
                <TextField
                  fullWidth
                  size='medium'
                  variant='outlined'
                  placeholder='LOC-12345'
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '6px',
                    },
                    mt: 2,
                    width: '300px',
                  }}
                  onChange={(e) =>
                    setInclusion({ ...inclusion, user_code: e.target.value })
                  }
                />
                <br />
                <br />
              </>
            </Box>

            <br />
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={() => setAddIncModal(false)}
            color='secondary'
          >
            Done
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            color='primary'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={postInclusion}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth='lg'
        fullwidth
        open={addExcModal}
        onClose={() => setAddExcModal(false)}
      >
        <DialogTitle>Add new exclusion</DialogTitle>

        <DialogContent>
          <div className='flex justify-between'>
            <br />

            <Box flex={1} mr={1}>
              <>
                <Typography fontFamily='Poppins'>Locum ID</Typography>
                <TextField
                  fullWidth
                  size='medium'
                  variant='outlined'
                  placeholder='LOC-12345'
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '6px',
                    },
                    mt: 2,
                    width: '300px',
                  }}
                  onChange={(e) =>
                    setExclusion({ ...exclusion, user_code: e.target.value })
                  }
                />
                <br />
                <br />
              </>
            </Box>

            <br />
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={() => setAddExcModal(false)}
            color='secondary'
          >
            Done
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            color='primary'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={postExclusion}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
