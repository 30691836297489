import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/clinic/profile/settings/notification`;
const urls = `${process.env.REACT_APP_API_URL}/admin/clinics`;

export default function AddNotifChannel({ addModal, setAddModal, fetchData }) {
  const { auth } = useAuth();
  const { clinicId } = useParams();
  const [data, setData] = useState([]);

  const [notif, setNotif] = useState({ channel_type: 'WA', channel_value: '' });
  const [warning, setWarning] = useState('');

  const validateWhatsApp = (value) => {
    if (!value.startsWith('+65') || !/^\+\d+$/.test(value)) {
      setWarning('Please enter a valid WhatsApp number!');
      return false;
    }
    setWarning('');
    return true;
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setWarning('Please enter a valid email address');
      return false;
    }
    setWarning('');
    return true;
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (notif?.channel_type === 'WA') {
      validateWhatsApp(value);
    } else if (notif?.channel_type === 'EMAIL') {
      validateEmail(value);
    }
    setNotif({ ...notif, channel_value: value });
  };

  useEffect(() => {
    if (auth.idToken) {
      axios
        .get(`${urls}/${clinicId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [auth.idToken, clinicId]);

  const handlePost = () => {
    if (
      notif?.channel_type === 'WA' &&
      !validateWhatsApp(notif.channel_value)
    ) {
      return;
    }
    if (
      notif?.channel_type === 'EMAIL' &&
      !validateEmail(notif.channel_value)
    ) {
      return;
    }

    const formData = {
      clinic_id: data._id,
      channel_type: notif?.channel_type,
      channel_value: notif?.channel_value,
      // channel_note: notif?.channel_note,
    };

    axios
      .post(`${url}/create`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Item added successfully:', response);
        setNotif({ ...notif, channel_value: '' });
        fetchData();
        toast.success('Item added successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(`${error.response?.data?.message || 'An error occurred'}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={addModal}
      onClose={() => setAddModal(false)}
    >
      <DialogTitle>Add new item</DialogTitle>

      <DialogContent>
        <div className='flex justify-between'>
          <br />
          <Box flex={1} mr={1}>
            <Typography fontFamily='Poppins'>Type*</Typography>
            <Select
              fullWidth
              displayEmpty
              value={notif.channel_type}
              onChange={(e) =>
                setNotif({
                  ...notif,
                  channel_type: e.target.value,
                  channel_value: '',
                })
              }
              sx={{ mt: 1, mb: '16px', borderRadius: '8px', width: '300px' }}
              MenuProps={{
                disablePortal: true,
                PaperProps: {
                  style: {
                    zIndex: 1302,
                  },
                },
              }}
            >
              <MenuItem disabled value=''>
                <em style={{ color: '#8C8C8C' }}>Select</em>
              </MenuItem>
              <MenuItem value='WA'>WhatsApp</MenuItem>
              <MenuItem value='EMAIL'>Email</MenuItem>
            </Select>

            <br />
            <br />
          </Box>

          <Box flex={1} mr={1}>
            {notif.channel_type === 'WA' && (
              <>
                <Typography fontFamily='Poppins'>Value*</Typography>
                <TextField
                  fullWidth
                  size='medium'
                  variant='outlined'
                  placeholder='+65'
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '6px',
                    },
                    mt: '5px',
                    width: '300px',
                  }}
                  value={notif.channel_value}
                  onChange={handleChange}
                />
                <br />
                <br />
              </>
            )}
          </Box>

          <Box flex={1} mr={1}>
            {notif.channel_type === 'EMAIL' && (
              <div>
                <TextField
                  fullWidth
                  size='medium'
                  variant='outlined'
                  placeholder='admin@clinic.com'
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '6px',
                    },
                    mt: '5px',
                    width: '300px',
                  }}
                  value={notif.channel_value}
                  onChange={handleChange}
                />
              </div>
            )}
            {warning && <p style={{ color: 'red', fontSize: 12 }}>{warning}</p>}
          </Box>
          {/* <div>Note</div>
          <br />
          <input
            placeholder='enter note (optional)'
            className='font-poppins w-full rounded-md'
            style={{
              width: '350px',
              height: '38px',
              fontSize: 14,
              color: '#2d3748',
              paddingLeft: 5,
            }}
            onChange={(e) =>
              setNotif({
                ...notif,
                channel_note: e.target.value,
              })
            }
          /> */}

          <br />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setAddModal(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          color='primary'
          onClick={handlePost}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
