import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}`;

export default function AddResume({
  openModalResume,
  setOpenModalResume,
  fetchDataResume,
}) {
  const { auth } = useAuth();
  const { userId } = useParams();
  const [getResume, setGetResume] = useState([]);

  const handleResume = () => {
    const formData = {
      resume: getResume.resume,
    };

    axios
      .post(`${url}/admin/resume/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Cert updated successfully:', response);
        setGetResume({ ...getResume });
        fetchDataResume();
        toast.success('Cert updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error('You have uploaded the certs. The file will not uploaded', {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFile = (e) => {
    const files = e.target.files[0];
    if (files) {
      setSelectedFile(files.name);
      setGetResume((prevInput) => ({
        ...prevInput,
        resume: files,
      }));
    }
  };
  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={openModalResume}
      onClose={() => setOpenModalResume(false)}
    >
      <DialogTitle>Add resume</DialogTitle>
      <br />

      <DialogContent>
        <div>
          <input
            type='file'
            id='resume'
            accept='image/jpeg, image/png, image/webp, image/svg+xml, application/pdf'
            onChange={handleFile}
            className='text-md w-1/3'
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setOpenModalResume(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => handleResume()}
          color='primary'
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
