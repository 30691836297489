import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}`;

export default function AddAchievement({ openModal, setOpenModal, fetchData }) {
  // const [openModal, setOpenModal] = useState(false);
  // const showModal = () => {
  //   setOpenModal(true);
  // };
  const { auth } = useAuth();
  const { userId } = useParams();
  const [getAchievement, setGetAchievement] = useState([]);

  const handleAchievement = () => {
    const formData = {
      item: getAchievement.item,
      achievement: getAchievement.achievement,
    };

    axios
      .post(`${url}/admin/achievements/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Achievement updated successfully:', response);
        setGetAchievement({ ...getAchievement });
        fetchData();
        toast.success('Achievement updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error('The type of achievement is required!', {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file.name);
      setGetAchievement((prevInput) => ({
        ...prevInput,
        achievement: file,
      }));
    }
  };
  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <DialogTitle>Add new achievement</DialogTitle>
      <br />

      <DialogContent>
        <div className='flex justify-between'>
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              height: '36px',
              width: '350px',
            }}
            value={getAchievement?.item}
            onChange={(e) =>
              setGetAchievement({
                ...getAchievement,
                item: e.target.value,
              })
            }
            placeholder='e.g Certificate of Completion'
          />
          &nbsp; &nbsp;
          <input
            type='file'
            id='achievement'
            accept='image/jpeg, image/png, image/webp, image/svg+xml, application/pdf'
            onChange={handleFile}
            className='text-md w-1/3'
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setOpenModal(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => handleAchievement()}
          color='primary'
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
