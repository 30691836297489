import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import BusinessDocuments from '@src/components/forms/businessDocuments.form';
import BusinessInformation from '@src/components/forms/businessInformation.form';
import MailingDetails from '@src/components/forms/mailingDetails.form.jsx';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = `${process.env.REACT_APP_API_URL}/admin`;
const urls = `${process.env.REACT_APP_API_URL}/ww-platform/admin/v2/organization/license/initiate`;

export default function OrganisationVerified() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { orgId } = useParams();
  const state = useLocation();
  const [open, setOpen] = useState();

  const handleClickOrg = (orgsId) => {
    navigate(`/view-branch/${orgsId}`);
  };

  const handleAdminOrg = (admId) => {
    navigate(`/view-admin-org/${admId}`);
  };
  const [getAdmin, setGetAdmin] = React.useState();
  const [createOrg, setCreateOrg] = React.useState(null);
  const [updateOrg, setUpdateOrg] = React.useState({
    business_name: '',
    type_of_company: '',
    business_registration_no: '',
    date_of_registration: '',
    business_address: '',
    postal_code: '',
    phone_number: '',
    mailing_details_business_name: '',
    mailing_details_delivery_address: '',
    mailing_details_postal_code: '',
    moh_licence: '',
    acra: '',
    valid_from: '',
    valid_until: '',
    status: '',
  });

  const [channel, setChannel] = React.useState({
    channel_type: '',
    channel_value: '',
    channel_note: '',
  });

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/organizations/${orgId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setCreateOrg(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, orgId]);

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/organizations/get-admins/${orgId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetAdmin(response.data.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, orgId]);

  React.useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/organizations/${orgId}/clinics`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setUpdateOrg(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, orgId]);

  function updateOrganisation() {
    const formData = {
      business_name: createOrg.business_name,
      type_of_company: createOrg.type_of_company,
      business_registration_no: createOrg.business_registration_no,
      date_of_registration: createOrg.date,
      business_address: createOrg.business_address,
      postal_code: createOrg.postal_code,
      phone_number: createOrg.phone_number,
      email: createOrg.email,
      mailing_details_business_name: createOrg.mailing_details_business_name,
      mailing_details_delivery_address:
        createOrg.mailing_details_delivery_address,
      mailing_details_postal_code: createOrg.mailing_details_postal_code,
      moh_licence: createOrg.moh_licence,
      acra: createOrg.acra,
      valid_from: createOrg.from,
      valid_to: createOrg.to,
      channel_type: createOrg.channel_type,
      channel_value: createOrg.channel_value,
      channel_note: createOrg.channel_note,
    };

    axios
      .patch(`${url}/organizations/${orgId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Organisation updated successfully', response.data);
        setCreateOrg({ ...createOrg });
        setOpen(true);
      });
    console.log(formData);
  }

  const [license, setLicense] = useState();
  const newDate = new Date();

  const initiateLicense = async () => {
    const license_start_at = newDate.toISOString().slice(0, 16);
    const ninetyDaysLater = new Date(newDate);
    ninetyDaysLater.setDate(ninetyDaysLater.getDate() + 90);
    const license_expired_at = ninetyDaysLater.toISOString().slice(0, 16);

    if (auth?.idToken) {
      const requestData = {
        request: {
          data: {
            license_key: 'uuid',
            license_is_active: true,
            license_package_type: 'TRIAL',
            license_start_at,
            license_expired_at,
            license_note: '',
          },
          metadata: {},
        },
        system: {
          audit: {
            do_audit_trail: false,
          },
        },
      };
      axios
        .post(`${urls}/${orgId}`, requestData, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          toast.success('License has been activated successfully', {
            position: 'top-center',
            autoClose: 2000,
          });
          setLicense(response.data.response.data);
        })
        .catch((error) => {
          console.log('Error', error.message);
          if (error.response && error.response.data) {
            const { system } = error.response.data;

            if (
              system &&
              system.success === false &&
              system.message_type === 'FAILED'
            ) {
              toast.error(system.message);
            }
          }
        });
    }
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    // navigate('/organisation');
  };

  const [banned, setBanned] = React.useState();

  const handleBannedModal = () => {
    setBanned(true);
  };

  const handleCloseBannedModal = () => {
    setBanned(false);
    navigate('/organisation');
  };

  function bannedOrganisation() {
    const formData = {
      status: 'banned',
    };
    axios
      .patch(`${url}/${orgId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account banned successfully', response.data);
        setCreateOrg({ ...createOrg, status: 'banned' });
        setBanned(true);
      })
      .catch((error) => {
        console.log('Error', error.message);
      });
  }

  const [suspend, setSuspend] = React.useState();

  const handleSuspendModal = () => {
    setSuspend(true);
  };

  const handleCloseSuspendModal = () => {
    setSuspend(false);
    navigate('/organisation');
  };

  function suspendOrganisation() {
    const formData = {
      status: 'suspended',
    };
    axios
      .patch(`${url}/${orgId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account suspended successfully', response.data);
        setCreateOrg({ ...createOrg, status: 'suspended' });
        setSuspend(true);
      })
      .catch((error) => {
        console.log('Error', error.message);
      });
  }

  const [openChangeModal, setOpenChangeModal] = useState(false);
  const showChangeModal = () => {
    setOpenChangeModal(true);
  };

  const [openBannedModal, setOpenBannedModal] = useState(false);
  const showBannedModal = () => {
    setOpenBannedModal(true);
  };

  const [openSuspendModal, setOpenSuspendModal] = useState(false);
  const showSuspendModal = () => {
    setOpenSuspendModal(true);
  };

  const [channelModal, setChannelModal] = useState(false);
  const showChannelModal = () => {
    setChannelModal(true);
  };

  function updateChannel() {
    const formData = {
      channel_type: channel.channel_type,
      channel_value: channel.channel_value,
      channel_note: channel.channel_note,
    };

    axios
      .post(`${url}/channel-notifications/${orgId}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        toast.success('The notification channel setting saved successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
        console.log('Channel updated successfully', response.data);
        setChannel({ ...channel });
        channelModal(true);
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  }

  return (
    <Box
      sx={{ width: '100%', fontFamily: 'Poppins', margin: 2 }}
      className='w-3/4'
    >
      <Box
        sx={{ width: 'auto', fontFamily: 'Poppins' }}
        className='flex justify-end px-4'
      >
        <Button
          variant='text'
          className='font-bold text-cyan-600'
          sx={{
            textTransform: 'none',
            paddingRight: 3,
            paddingBottom: 2,
          }}
          onClick={() => showChangeModal()}
        >
          Activate License
        </Button>
      </Box>
      <div className='grid grid-cols-2'>
        <div className='col-end-2'>
          <Box
            className='rounded-xl'
            sx={{
              height: '151px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Organisation
            </div>
            <div className='flex justify-center from-cyan-500 to-cyan-600 text-2xl font-bold text-cyan-600 underline'>
              <Link className='text-cyan-600' to='/organisation-finance'>
                {createOrg?.code}
              </Link>
            </div>
          </Box>

          <Box
            className='rounded-xl'
            sx={{
              height: '585px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Business Information
            </div>
            <BusinessInformation
              createOrg={createOrg}
              setCreateOrg={setCreateOrg}
              updateOrg={updateOrg}
              setUpdateOrg={setUpdateOrg}
            />
          </Box>

          <Box
            className='rounded-xl'
            sx={{
              height: '323px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-6 text-lg font-bold'>
              Mailing Details
            </div>
            <MailingDetails
              createOrg={createOrg}
              setCreateOrg={setCreateOrg}
              updateOrg={updateOrg}
              setUpdateOrg={setUpdateOrg}
            />
          </Box>

          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <Typography
              fontFamily='Poppins'
              px={3}
              py={4}
              fontSize='1.125rem'
              fontWeight='bold'
            >
              Setting for Notification Channel
            </Typography>

            <Typography px={3} fontSize={14}>
              Do you want to make this email/phone number as a clinic
              notification channel?
            </Typography>

            <Box display='flex' justifyContent='end' pr={3} mt={2}>
              <Button
                variant='contained'
                sx={{
                  textTransform: 'none',
                  backgroundColor: '#9081D5',
                  fontWeight: 700,
                  borderRadius: 2,
                  width: '80px',
                }}
                onClick={showChannelModal}
              >
                Yes
              </Button>
              &nbsp; &nbsp;
              <Button
                className='text-[#9081D5]'
                variant='outlined'
                sx={{
                  textTransform: 'none',
                  backgroundColor: 'white',
                  fontWeight: 700,
                  borderRadius: 2,
                  borderColor: '#9081D5',
                }}
                onClick={() => handleClickOrg(createOrg?._id)}
              >
                Check
              </Button>
            </Box>
            <br />
          </Box>
        </div>
        <div className='col-auto'>
          <Box
            className='rounded-xl'
            sx={{
              height: '412px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-6 text-lg font-bold'>
              Business Documents
            </div>
            <BusinessDocuments
              createOrg={createOrg}
              setCreateOrg={setCreateOrg}
              updateOrg={updateOrg}
              setUpdateOrg={setUpdateOrg}
            />
          </Box>
          <Box
            className='rounded-xl'
            sx={{
              height: '427px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-6  text-lg font-bold'>
              Branches
            </div>
            <div>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 500,
                }}
                className='px-2'
              >
                {Array.isArray(updateOrg) && updateOrg.length > 0 ? (
                  updateOrg.slice(0, 3).map((row) => (
                    <>
                      <ListItem alignItems='flex-start'>
                        <ListItemAvatar>
                          <Avatar
                            src={`${process.env.REACT_APP_API_URL}${row?.logo}`}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <span className='font-bold'>{row?.clinicName}</span>
                          }
                          secondary={
                            <>
                              <Typography
                                sx={{ display: 'inline' }}
                                component='span'
                                variant='body2'
                                color='text.secondary'
                              >
                                {row?.clinicAddress}
                              </Typography>
                              <br />
                              <Typography
                                sx={{ display: 'inline' }}
                                component='span'
                                variant='body2'
                                color='text.secondary'
                              >
                                {row?.code}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      {updateOrg.indexOf(row) < 2 && (
                        <Divider
                          variant='inset'
                          component='li'
                          className='w-3/4'
                        />
                      )}
                    </>
                  ))
                ) : (
                  <div className='flex justify-center py-20 px-8 text-sm text-gray-600'>
                    This organisation has no clinic
                  </div>
                )}
              </List>
            </div>
            <div className='flex justify-end px-6'>
              {Array.isArray(updateOrg) && updateOrg.length > 0 && (
                <Button
                  variant='text'
                  className='text-xs font-bold text-cyan-600'
                  sx={{
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: 'white',
                    },
                  }}
                  onClick={() => handleClickOrg(createOrg?._id)}
                >
                  View more
                </Button>
              )}
            </div>
          </Box>

          <Box
            className='rounded-xl'
            sx={{
              height: '337px',
              width: 'auto',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-6  text-lg font-bold'>
              Admin Listing
            </div>
            <div>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 500,
                }}
                className='px-2'
              >
                {Array.isArray(getAdmin) && getAdmin.length > 0 ? (
                  getAdmin.slice(0, 3).map((row) => (
                    <>
                      <ListItem alignItems='flex-start'>
                        <ListItemText
                          primary={
                            <span className='font-bold'>{row?.full_name}</span>
                          }
                          secondary={
                            <>
                              <Typography
                                sx={{ display: 'inline' }}
                                component='span'
                                variant='body2'
                                color='text.secondary'
                              >
                                {row?.email}
                              </Typography>
                              &nbsp; | &nbsp;
                              <Typography
                                sx={{ display: 'inline' }}
                                component='span'
                                variant='body2'
                                color='text.secondary'
                              >
                                {row?.role}
                              </Typography>
                              {/* <br />
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component='span'
                                  variant='body2'
                                  color='text.secondary'
                                >
                                  {row?.clinicName || 'No clinic added'}
                                </Typography> */}
                            </>
                          }
                        />
                      </ListItem>
                      {getAdmin.indexOf(row) < 2 && (
                        <Divider
                          sx={{ marginLeft: 2 }}
                          variant='inset'
                          component='li'
                          className='w-3/4'
                        />
                      )}
                    </>
                  ))
                ) : (
                  <div className='flex justify-center py-20 px-8 text-sm text-gray-600'>
                    <p>
                      This organisation has no admin, please add admin to
                      activate license!
                    </p>
                  </div>
                )}
              </List>
            </div>
            <div className='flex justify-end px-6'>
              <Button
                variant='text'
                className='text-xs font-bold text-cyan-600'
                sx={{
                  textTransform: 'none',

                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }}
                onClick={() => handleAdminOrg(createOrg?._id)}
              >
                View more
              </Button>
            </div>
          </Box>
        </div>
      </div>
      <Box
        className='rounded-xl'
        sx={{
          height: '100px',
          width: 'auto',
          backgroundColor: 'white',
          margin: 2,
        }}
      >
        <div className='flex justify-end px-12 py-8'>
          <Button
            sx={{
              textTransform: 'none',
              width: '130px',
              height: '38px',
            }}
            className='text-md rounded-xl bg-cyan-600 font-bold text-white'
            variant='contained'
            onClick={() => {
              updateOrganisation();
              handleOpenModal();
            }}
          >
            Update
          </Button>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <Button
            sx={{
              textTransform: 'none',
              width: '130px',
              height: '38px',
            }}
            className='rounded-xl bg-yellow-500 text-sm font-bold text-white'
            variant='contained'
            onClick={showBannedModal}
          >
            Ban
          </Button>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <Button
            sx={{
              textTransform: 'none',
              width: '130px',
              height: '38px',
            }}
            className='rounded-xl bg-purple-500 text-sm font-bold text-white'
            variant='contained'
            onClick={showSuspendModal}
          >
            Suspend
          </Button>
        </div>
      </Box>
      <br />
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Update Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The organisation has been updated successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={banned} onClose={handleCloseBannedModal}>
        <DialogTitle>Banned Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The organisation has been banned successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBannedModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={suspend} onClose={handleCloseSuspendModal}>
        <DialogTitle>Suspended Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The organisation has been suspended successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuspendModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openBannedModal} onClose={setOpenBannedModal}>
        <DialogTitle>Confirm Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to ban the organisation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenBannedModal(false)}
            color='secondary'
            sx={{ textTransform: 'none' }}
          >
            Done
          </Button>
          <Button
            onClick={() => {
              handleBannedModal();
              bannedOrganisation();
            }}
            color='error'
            sx={{ textTransform: 'none' }}
          >
            Ban
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSuspendModal} onClose={setOpenSuspendModal}>
        <DialogTitle>Confirm Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to suspend the organisation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenSuspendModal(false)}
            color='secondary'
            sx={{ textTransform: 'none' }}
          >
            Done
          </Button>
          <Button
            onClick={() => {
              handleSuspendModal();
              suspendOrganisation();
            }}
            color='error'
            sx={{ textTransform: 'none' }}
          >
            Suspend
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openChangeModal} onClose={setOpenChangeModal}>
        <DialogTitle>Confirm Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to activate the license?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenChangeModal(false)}
            color='secondary'
            sx={{ textTransform: 'none' }}
          >
            Done
          </Button>
          <Button
            onClick={initiateLicense}
            color='primary'
            sx={{ textTransform: 'none' }}
            // disabled={license?.license_is_active}
          >
            Activate
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullwidth
        open={channelModal}
        onClose={() => setChannelModal(false)}
        disableEnforceFocus
      >
        <DialogTitle>Add new channel</DialogTitle>
        <br />

        <DialogContent>
          <div className='flex justify-between'>
            <Box flex={1} mr={1}>
              <Typography fontFamily='Poppins'>Type*</Typography>
              <Select
                fullWidth
                displayEmpty
                value={channel?.channel_type || ''}
                onChange={(e) =>
                  setChannel({
                    ...channel,
                    channel_type: e.target.value,
                  })
                }
                sx={{ mt: 1, mb: '16px', borderRadius: '8px', width: '300px' }}
                MenuProps={{
                  disablePortal: true,
                  PaperProps: {
                    style: {
                      zIndex: 1302,
                    },
                  },
                }}
              >
                <MenuItem disabled value=''>
                  <em style={{ color: '#8C8C8C' }}>Select</em>
                </MenuItem>
                <MenuItem value='WA'>WhatsApp</MenuItem>
                <MenuItem value='EMAIL'>Email</MenuItem>
              </Select>
            </Box>
            <Box flex={1} mr={1}>
              <Typography fontFamily='Poppins'>Value*</Typography>
              <TextField
                fullWidth
                size='medium'
                variant='outlined'
                placeholder='Enter email/phone number'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '6px',
                  },
                  mt: '5px',
                }}
                value={channel?.channel_value}
                onChange={(e) =>
                  setChannel({
                    ...channel,
                    channel_value: e.target.value,
                  })
                }
              />
              <br />
              <br />
            </Box>
            <Box flex={1} mr={1}>
              <Typography fontFamily='Poppins'>Notes*</Typography>
              <TextField
                fullWidth
                size='medium'
                variant='outlined'
                placeholder='Enter notes'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '6px',
                  },
                  mt: '5px',
                }}
                value={channel?.channel_note}
                onChange={(e) =>
                  setChannel({
                    ...channel,
                    channel_note: e.target.value,
                  })
                }
              />
              <br />
              <br />
            </Box>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={() => setChannelModal(false)}
            color='secondary'
          >
            Done
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={() => updateChannel()}
            color='primary'
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
