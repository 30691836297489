import '@fontsource/poppins';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

import React from 'react';

// eslint-disable-next-line no-unused-vars
export default function AddWebCampaignForm({
  input,
  setInput,
  updates,
  setUpdates,
}) {
  const handleStartDateChange = (newDate) => {
    if (newDate) {
      setInput({
        ...input,
        start_dates: newDate.format('YYYY-MM-DD'),
        start_times: newDate.format('HH:mm:ss'),
      });
    }
  };

  const handleEndDateChange = (newDate) => {
    setInput({
      ...input,
      end_dates: newDate ? newDate.format('YYYY-MM-DD') : '',
      end_times: newDate ? newDate.format('HH:mm') : '',
    });
  };
  return (
    <>
      <div className='flex px-2 py-4'>
        <div className='col-span-3 w-full px-6 py-2'>
          <label className='block text-sm font-medium leading-6 text-gray-600'>
            Campaign Title
          </label>
          <div className='mt-2'>
            <input
              placeholder='Enter Title'
              style={{ height: '29px' }}
              className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              value={
                input
                  ? input.campaign_title || ''
                  : updates.campaign_title || ''
              }
              onChange={(e) =>
                input
                  ? setInput({ ...input, campaign_title: e.target.value })
                  : setUpdates({ ...updates, campaign_title: e.target.value })
              }
            />
          </div>
        </div>

        <div className='px-3 sm:col-span-3'>
          <label className='block py-2 text-sm font-medium leading-6 text-gray-600'>
            Start Date/Time*
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              sx={{ width: '300px' }}
              className='bg-white'
              format='DD MMM YYYY-hh:mm A'
              value={
                input?.start ? dayjs(input?.start).tz('Asia/Singapore') : null
              }
              onChange={handleStartDateChange}
            />
          </LocalizationProvider>
        </div>

        <div className='px-4 sm:col-span-3'>
          <label className='block py-2 text-sm font-medium leading-6 text-gray-600'>
            End Date/Time*
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              sx={{ width: '300px' }}
              className='bg-white'
              format='DD MMM YYYY-hh:mm A'
              value={input?.end ? dayjs(input?.end).tz('Asia/Singapore') : null}
              onChange={handleEndDateChange}
            />
          </LocalizationProvider>
        </div>

        <div className='px-2 sm:col-span-3'>
          <label className='block py-1 text-sm font-medium leading-6 text-gray-600'>
            Reorder Sort
          </label>
          <div className='mt-2'>
            <select
              style={{ height: '56px', width: '120px' }}
              onChange={(e) => setInput({ ...input, sorts: e.target.value })}
              value={input ? input?.sorts : updates?.sort}
              className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
            >
              <option value={'0'}>0</option>
              <option value={'1'}>1</option>
              <option value={'2'}>2</option>
              <option value={'3'}>3</option>
              <option value={'4'}>4</option>
              <option value={'5'}>5</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
}
