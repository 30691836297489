/* eslint-disable react/no-unescaped-entities */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import LabTabs from '@src/components/commons/tabs.common';
import DetailLicenseForm from '@src/components/forms/detailLicense.form';
import LicensePackageDetail from '@src/components/modals/licensePackageDetail';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ClinicList from './clinicList';
import LicenseInvoice from './licenseInvoice';
import LicenseItemList from './licenseItemList';

const url = `${process.env.REACT_APP_API_URL}/ww-platform/admin/v2/organization/license`;

export default function DetailLicense() {
  const navigate = useNavigate();
  const state = useLocation();
  const { auth } = useAuth();
  const { licenseId } = useParams();
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [list, setList] = useState({
    key: '',
    is_active: '',
    start_at: '',
    expired_at: '',
    package_type: '',
    notes: '',
  });
  const [update, setUpdate] = useState([]);
  const [items, setItems] = useState([]);
  const [org, setOrg] = useState([]);
  const [branch, setBranch] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [packages, setPackage] = useState([]);
  const [detail, setDetail] = useState({ license_package_is_active: '' });

  const fetchData = () => {
    if (auth?.idToken) {
      axios
        .get(`${url}/detail/${licenseId}`, {
          headers: {
            Authorization: `Bearer ${auth?.idToken}`,
          },
        })
        .then((response) => {
          setItems(
            response.data.response.data.organization_license_info
              .license_feature_items,
          );
          setOrg(response.data.response.data.organization_info);
          setList(response.data.response.data.organization_license_info);
          setDetail(
            response.data.response.data.organization_license_info
              .license_package_info,
          );
          setBranch(
            response.data.response.data.organization_info.branch_info
              .clinic_items,
          );
          // setPackage(response.data.response.data);
        })
        .catch((error) => {
          console.log('Error', error.message);
        });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleActive = async () => {
    if (auth?.idToken) {
      axios
        .put(
          `${url}/activate/${licenseId}`,
          {},
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${auth?.idToken}`,
            },
          },
        )
        .then((response) => {
          const updatedList = response.data.response.data;

          setList({
            ...list,
            license_is_active: updatedList.license_is_active,
          });

          fetchData();

          toast.success('License has been activated successfully', {
            position: 'top-center',
            autoClose: 2000,
          });
        })
        .catch((error) => {
          console.log('Error', error.message);
          toast.error('Failed to activate license', {
            position: 'top-center',
            autoClose: 2000,
          });
        });
    }
  };

  const handleDeactive = async () => {
    if (auth?.idToken) {
      axios
        .put(
          `${url}/deactivate/${licenseId}`,
          {},
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${auth?.idToken}`,
            },
          },
        )
        .then((response) => {
          const updatedList = response.data.response.data;

          setList({
            ...list,
            license_is_active: updatedList.license_is_active,
          });

          fetchData();

          toast.success('License has been deactivated successfully', {
            position: 'top-center',
            autoClose: 2000,
          });
        })
        .catch((error) => {
          console.log('Error', error.message);
          toast.error('Failed to deactivate license', {
            position: 'top-center',
            autoClose: 2000,
          });
        });
    }
  };

  const handleGrant = async (licensesId, featureItemId) => {
    if (auth?.idToken) {
      axios
        .put(
          `${url}/feature/grant/${licensesId}/${featureItemId}`,
          {},
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${auth?.idToken}`,
            },
          },
        )
        .then(() => {
          fetchData();
          toast.success('License has been granted successfully', {
            position: 'top-center',
            autoClose: 2000,
          });
        })
        .catch((error) => {
          console.log('Error', error.message);
          toast.error('Failed to grant license', {
            position: 'top-center',
            autoClose: 2000,
          });
        });
    }
  };

  const handleRevoke = async (licensesId, featureItemId) => {
    if (auth?.idToken) {
      axios
        .put(
          `${url}/feature/revoke/${licensesId}/${featureItemId}`,
          {},
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${auth?.idToken}`,
            },
          },
        )
        .then(() => {
          fetchData();
          toast.success('License has been revoked successfully', {
            position: 'top-center',
            autoClose: 2000,
          });
        })
        .catch((error) => {
          console.log('Error', error.message);
          toast.error('Failed to revoke license', {
            position: 'top-center',
            autoClose: 2000,
          });
        });
    }
  };

  const updateLicense = async () => {
    if (auth?.idToken) {
      const formData = {
        request: {
          data: {
            license_key: list.license_key,
            license_package_type: list.license_package_type,
            license_start_at: list.license_start_at,
            license_expired_at: list.license_expired_at,
            license_note: list.license_notes,
          },
          metadata: {},
        },
        system: {
          audit: {
            do_audit_trail: false,
          },
        },
      };

      axios
        .put(`${url}/update/${licenseId}`, formData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setUpdate(response.data);
          toast.success('License has been updated successfully', {
            position: 'top-center',
            autoClose: 2000,
          });
          // setUpdateModal(true);
        })
        .catch((error) => {
          toast.error('Failed to update license, please refresh', error);
        });
      console.log(formData);
    }
  };

  const licensePackage = async () => {
    if (auth?.idToken) {
      const requestData = {
        filter: {
          license_package_type: '',
          license_package_code: '',
          license_package_name: '',
          license_package_active_status: '',
        },
        pagination: {
          page: 1,
          page_size: 50,
        },
      };

      axios
        .post(`${url}-package-ref/search`, requestData, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${auth?.idToken}`,
          },
        })
        .then((response) => {
          setPackage(response.data.response.data);
          fetchData();
        })
        .catch((error) => {
          console.log('Error', error.message);
          // toast.error('No data found', {
          //   position: 'top-center',
          //   autoClose: 2000,
          // });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleBack = async () => {
    navigate(state?.prevLocation || -1);
  };

  const [openChangeModal, setOpenChangeModal] = useState(false);
  const showChangeModal = () => {
    setOpenChangeModal(true);
  };

  const [openPackageModal, setOpenPackageModal] = useState(false);
  const showPackageModal = () => {
    setOpenPackageModal(true);
  };

  const categories = [
    {
      idx: 0,
      title: 'Clinic List',
      component: (
        <div>
          <ClinicList branch={branch} />
        </div>
      ),
    },
    {
      idx: 1,
      title: 'License Invoice',
      component: (
        <div>
          <LicenseInvoice invoice={invoice} />
        </div>
      ),
    },
  ];
  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins', margin: 2 }}>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-8 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <div className='grid grid-cols-3'>
        <Box
          className='rounded-xl'
          sx={{
            height: '250px',
            width: 'auto',
            backgroundColor: 'white',
            margin: 2,
          }}
        >
          <div className='grid grid-cols-1'>
            <div className='col-end-1'>
              <div className='font-poppins py-4 px-4 text-lg font-bold'>
                Organisation Info:
              </div>
              <div className='font-poppins flex justify-start px-8 py-1.5 text-sm text-gray-600'>
                Organisation ID
              </div>
              <div className='font-poppins flex justify-start px-8 py-1.5 text-sm text-gray-600'>
                Business Name
              </div>
              <div className='font-poppins flex justify-start px-8 py-1.5 text-sm text-gray-600'>
                Organisation Status
              </div>
              <div className='font-poppins flex justify-start px-8 py-1.5 text-sm text-gray-600'>
                Total Clinic
              </div>
            </div>
            <div className='col-auto'>
              <div className='font-poppins py-4 px-4 text-lg font-bold text-white'>
                Organisation Info:
              </div>
              <div className='font-poppins flex justify-start px-2 py-1.5 text-sm font-bold text-gray-500'>
                {org?.organization_code || '-'}
              </div>
              <div className='font-poppins flex px-2 py-1.5 text-sm font-bold text-gray-500'>
                <div className='flex'> {org?.organization_name || '-'}</div>
              </div>
              <div className='font-poppins flex justify-start px-2 py-1.5 text-sm font-bold text-gray-500'>
                {org?.organization_status || '-'}
              </div>
              <div className='font-poppins flex justify-start px-2 py-1.5 text-sm font-bold text-gray-500'>
                {org?.branch_info?.clinic_count || '0'} clinics
              </div>
            </div>
          </div>
        </Box>
        <Box
          className='rounded-xl'
          sx={{
            height: '600px',
            width: '545px',
            backgroundColor: 'white',
            margin: 2,
          }}
        >
          <DetailLicenseForm
            org={org}
            list={list}
            setList={setList}
            update={update}
            setUpdate={setUpdate}
            showChangeModal={showChangeModal}
            open={open}
            setOpen={setOpen}
            opens={opens}
            setOpens={setOpens}
            updateLicense={updateLicense}
            showPackageModal={showPackageModal}
            licensePackage={licensePackage}
            detail={detail}
          />
        </Box>
      </div>
      <Box sx={{ width: '100%' }} />
      <Box sx={{ width: '100%' }}>
        <span className='flex w-2/3 flex-col items-start py-4 px-8'>
          <LicenseItemList
            items={items}
            setItems={setItems}
            handleGrant={handleGrant}
            handleRevoke={handleRevoke}
          />
          <br />
        </span>
      </Box>

      <Box sx={{ width: '100%' }} />
      <Box sx={{ width: '100%' }} className='w-full px-6'>
        <LabTabs menus={categories} />
      </Box>

      <Dialog open={openChangeModal} onClose={setOpenChangeModal}>
        <DialogTitle>Confirm Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change this status?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenChangeModal(false)}
            color='secondary'
            sx={{ textTransform: 'none' }}
          >
            Done
          </Button>
          <Button
            onClick={handleActive}
            color='primary'
            sx={{ textTransform: 'none' }}
            disabled={list?.license_is_active}
          >
            Activate
          </Button>
          <Button
            onClick={handleDeactive}
            color='error'
            sx={{ textTransform: 'none' }}
            disabled={!list?.license_is_active}
          >
            Deactivate
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth='lg'
        fullwidth
        open={openPackageModal}
        onClose={() => setOpenPackageModal(false)}
      >
        <DialogTitle>License Package Pricing</DialogTitle>
        <br />
        <DialogContent>
          Old License Package Type:{' '}
          <span style={{ fontWeight: 'bold' }}>
            {detail.license_package_name || 'no package added'}
          </span>
        </DialogContent>
        <DialogContent>
          <LicensePackageDetail
            packages={packages}
            setPackage={setPackage}
            setList={setList}
            setDetail={setDetail}
            fetchData={fetchData}
            detail={detail}
          />
        </DialogContent>

        <DialogActions>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={() => setOpenPackageModal(false)}
            color='secondary'
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
