import Container from '@src/components/commons/containerPage.common';
import LabTabs from '@src/components/commons/tabs.common';

import { useLocation } from 'react-router-dom';

import ITSystemItemNurse from './ITSystemNurse';
import CountryCode from './countryCode';
import ResidenStatus from './residenStatus';
import SkillsItemNurse from './skillNurse';

export default function MasterData() {
  const path = useLocation().pathname;
  const categories = [
    {
      idx: 0,
      title: 'Country',
      component: (
        <div className='px-6'>
          <CountryCode />
        </div>
      ),
    },
    {
      idx: 1,
      title: 'Residential Status',
      component: (
        <div className='px-6'>
          <ResidenStatus />
        </div>
      ),
    },
    {
      idx: 2,
      title: 'Skills for Nurse',
      component: (
        <div className='px-6'>
          <SkillsItemNurse />
        </div>
      ),
    },
    {
      idx: 3,
      title: 'IT Systems for Nurse',
      component: (
        <div className='px-6'>
          <ITSystemItemNurse />
        </div>
      ),
    },
  ];
  return (
    <Container pathUrl={path}>
      <div className='py-8'>
        <LabTabs menus={categories} />
      </div>
    </Container>
  );
}
