import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/admin/users`;

export default function InclusionUserList({
  clinic,
  inc,
  loading,
  fetchDataInclusion,
}) {
  const { auth } = useAuth();
  const { clinicId } = useParams();
  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDelete = async (id) => {
    const formData = {
      user_id: id,
      clinic_id: clinicId,
    };
    try {
      const response = await axios.delete(`${url}/delete-inclusion-by-clinic`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
          'My-Custom-Header': 'foobar',
        },
        data: formData,
      });
      console.log(response);
      setDeleteStatus('Delete successful');
      toast.success('User removed successfully', {
        autoClose: 2000,
        position: 'top-center',
      });
    } catch (error) {
      console.error(error);
      toast.error(`${error.response.data.message}`, {
        position: 'top-center',
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    fetchDataInclusion();
  }, [deleteStatus]);

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
      {loading ? (
        <div className='font-poppins py-6 px-8 text-gray-500'>Loading...</div>
      ) : (
        <span className='flex w-3/4 flex-col items-start  pr-20'>
          <TableContainer
            className='rounded-xl'
            component={Paper}
            style={{
              width: '100%',
              paddingBottom: '12px',
              paddingLeft: '12px',
              paddingRight: '12px',
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ width: '200px' }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '100px' }}
                  >
                    Role
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '100px' }}
                  >
                    Code
                  </TableCell>

                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '120px' }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(inc) && inc.length > 0 ? (
                  inc
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        hover
                        role='checkbox'
                        tabIndex={-1}
                        key={row._id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component='th'
                          scope='row'
                          className='border-white text-sm font-bold text-gray-500'
                        >
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '12px',
                            }}
                          >
                            {row?.user_id?.full_name || 'n/a'}
                          </span>
                        </TableCell>
                        <TableCell
                          className='text-md border-white font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {row?.user_id?.role
                            ? row.user_id?.role
                                .replace(/_/g, ' ')
                                .split(' ')
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() +
                                    word.slice(1),
                                )
                                .join(' ')
                            : 'n/a'}
                        </TableCell>
                        <TableCell
                          className='text-md border-white font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {row?.user_id?.role_id || 'n/a'}
                        </TableCell>
                        <TableCell
                          className='text-md border-white font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          <Button
                            variant='contained'
                            className='text-xs  font-bold text-red-500'
                            sx={{
                              textTransform: 'none',
                              backgroundColor: '#FAF9FC',
                              '&:hover': {
                                backgroundColor: 'white',
                              },
                              height: '36px',
                            }}
                            onClick={() =>
                              showDeleteConfirmationDialog(row?.user_id?._id)
                            }
                          >
                            Remove from inclusion
                          </Button>
                          {showDeleteConfirmation && (
                            <Dialog
                              open={showDeleteConfirmation}
                              onClose={handleConfirmationDialogClose}
                            >
                              <DialogTitle>Confirm Delete</DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  Are you sure you want to remove this user from
                                  inclusion?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={handleConfirmationDialogClose}
                                  color='primary'
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={handleConfirmationDialogConfirm}
                                  color='error'
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      className='border border-white'
                      colSpan={6}
                      align='center'
                    >
                      <div className='font-poppins py-6 text-gray-500'>
                        No data
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <Box
              className='flex justify-end py-3'
              sx={{ width: '100%', fontFamily: 'Poppins' }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component='div'
                count={inc?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        </span>
      )}
    </Box>
  );
}
