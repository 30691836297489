import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CreateAdminOrg from '@src/components/forms/createAdmin.form.jsx';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = `${process.env.REACT_APP_API_URL}/admin/organizations`;

export default function CreateAdminOrganisation() {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const { orgId } = useParams();
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const state = useLocation();
  const handleBack = () => {
    navigate(state?.prevLocation || -1);
  };

  const [groupData, setGroupData] = useState({});
  const [createAdmin, setCreateAdmin] = React.useState({
    name: '',
    email: '',
    password: '',
    companyId: '',
  });

  React.useEffect(() => {
    if (auth.idToken && typeof orgId === 'string' && orgId.length === 24) {
      axios
        .get(`${url}/${orgId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          console.log('CA:', response);
          setGroupData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  function createAdminOrg() {
    const formData = {
      name: createAdmin.name,
      email: createAdmin.email,
      password: createAdmin.password,
      companyId: groupData._id,
    };

    axios
      .post(`${url}/create-admin`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account created successfully:', response.data);
        setCreateAdmin({ ...createAdmin });
        toast.success('Account created successfully', {
          autoClose: 2000,
          position: 'top-center',
        });
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error(
          'The name/email address is already in use by another account.',
          {
            autoClose: 2000,
            position: 'top-center',
          },
        );
      });
    // console.log(formData);
  }

  const handleOpenModal = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    // navigate('/organisation');
  };

  const handleSaveButtonClick = () => {
    if (!createAdmin?.name) {
      toast.error('Name is required.');
      return;
    }
    if (!createAdmin?.email) {
      toast.error('Email is required.');
      return;
    }
    if (!createAdmin?.password) {
      toast.error('Password is required.');
      return;
    }
    setTimeout(() => {
      createAdminOrg();
      // navigate(state?.prevLocation || -1);
      handleOpenModal();
      // toast.success('Admin has been created');
    }, 1000);
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }}>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>

      <Box sx={{ width: '100%', fontFamily: 'Poppins' }} className='w-3/4 py-8'>
        <div className='flex justify-center'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '531px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-8 px-4 text-lg font-bold'>
              Sign-up Information
            </div>
            <CreateAdminOrg
              createAdmin={createAdmin}
              setCreateAdmin={setCreateAdmin}
            />
            <div className='flex justify-center px-6 py-4'>
              <Button
                sx={{
                  textTransform: 'none',
                  width: '200px',
                  height: '48px',
                }}
                className='text-md rounded-md bg-cyan-600 font-bold text-white'
                variant='contained'
                onClick={handleSaveButtonClick}
              >
                Sign up
              </Button>
            </div>
            <br />
          </Box>
        </div>
      </Box>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Save Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The Admin account has been created successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
