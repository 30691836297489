/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import '@fontsource/poppins';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const url = `${process.env.REACT_APP_API_URL}/admin/resource`;

const options = [
  { value: 'Doctors', label: 'Doctors' },
  { value: 'Clinic Assistants', label: 'Clinic Assistants' },
  { value: 'Nurses', label: 'Nurses' },
  { value: 'personal.specialist', label: 'Hub-Specialist' },
  { value: 'personal.allied-healthcare', label: 'Hub-Allied Healthcare' },
  { value: 'org.healthcare', label: 'Hub-Organisations' },
  { value: 'personal.gp', label: 'Hub-GP Locum' },
  // { value: 'ALL', label: 'ALL' },
];

function CheckboxGroup({ selectedValues = [], onChange }) {
  return (
    <div className='text-md py-2 px-2 text-gray-600'>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                className='py-1'
                style={{ color: '#1788b9' }}
                checked={selectedValues.includes(option.value)}
                onChange={(e) => onChange(option.value, e.target.checked)}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </div>
  );
}

export default function UpdateResource() {
  const { auth } = useAuth();
  const { resId } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [inputRes, setInputRes] = useState({});
  const [updateRes, setUpdateRes] = useState({
    title: '',
    desc: '',
    image: null,
    link: '',
    type: '',
    role: '',
    featur: '',
    sort: '',
  });

  const handleCheckboxChange = (event) => {
    setInputRes({ ...inputRes, featur: event.target.checked });
  };

  useEffect(() => {
    axios
      .get(`${url}/${resId}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        const { data } = response;
        setInputRes({
          res_title: data.res_title || '',
          res_desc: data.res_desc || '',
          res_link: data.res_link || '',
          res_type: data.res_type || '',
          roles: data.roles || [],
          res_image: data.res_image || '',
          sorts: data.sorts || '',
        });
      })
      .catch((error) => {
        console.log('Error retrieving data:', error);
      });
  }, [auth, resId]);

  const putResource = () => {
    const formData = new FormData();
    formData.append('res_title', inputRes?.res_title);
    formData.append('res_link', inputRes?.res_link);
    formData.append('res_desc', inputRes?.res_desc);
    formData.append('res_image', inputRes?.res_image);
    formData.append('res_type', inputRes?.res_type);
    if (Array.isArray(inputRes.roles) && inputRes.roles.length > 0) {
      inputRes.roles.forEach((role) => {
        formData.append('roles[]', role);
      });
    } else {
      console.warn('No roles provided or roles is not an array');
    }
    formData.append('res_featur', inputRes?.res_featur);
    formData.append('sorts', inputRes?.sorts);
    axios
      .put(`${url}/${resId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Resource updated successfully:', response.data);
        setOpen(true);
      })
      .catch((error) => {
        console.log('Error updating resource:', error);
      });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setPreviewUrl(URL.createObjectURL(file));
    setInputRes((prevFormInput) => ({ ...prevFormInput, res_image: file }));
  };

  const handleBack = () => {
    navigate('/resources');
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    navigate('/resources');
  };

  const handleRole = (role, checked) => {
    setInputRes((prevState) => {
      const roles = checked
        ? [...prevState.roles, role]
        : prevState.roles.filter((r) => r !== role);
      return { ...prevState, roles };
    });
  };

  return (
    <Box sx={{ width: '100%', fontFamily: 'Poppins' }} className='w-2/3 px-14'>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>

      <Box
        className='rounded-xl py-8 px-4'
        sx={{
          height: 'auto',
          width: 'auto',
          backgroundColor: 'white',
          margin: 2,
        }}
      >
        <div className='flex justify-end px-8'>
          <Button
            // variant='outlined'
            className='text-md rounded-md bg-cyan-600 px-4 font-bold text-white'
            sx={{ textTransform: 'none', height: '45px', width: '150px' }}
            onClick={() => {
              putResource();
              handleOpenModal();
            }}
          >
            Update
          </Button>
        </div>
        <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-5 sm:grid-cols-6'>
          <div className='col-span-full'>
            <Box
              sx={{
                width: '200px',
                height: '170px',
              }}
            >
              <label className='text-md block font-bold leading-6 text-gray-600'>
                Add Resource Image (Optional)
              </label>
              <div className='flex justify-between py-2'>
                <Box
                  sx={{
                    width: '120px',
                    height: '120px',
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      margin: 2,
                      width: '90px',
                      height: '90px',
                      position: 'relative',
                      overflow: 'hidden',
                    }}
                    className='flex justify-center rounded-xl bg-gray-300'
                  >
                    <input
                      type='file'
                      accept='image/*'
                      onChange={(e) => handleFileChange(e)}
                      name='files'
                      style={{ display: 'none' }}
                      id='upload-file-input'
                    />

                    {selectedFile || previewUrl || inputRes?.res_image ? (
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          position: 'relative',
                        }}
                      >
                        <img
                          src={previewUrl || inputRes.res_image}
                          alt=''
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                    ) : null}

                    <label htmlFor='upload-file-input'>
                      <Icon
                        icon='gala:add'
                        color='#8c8c8c'
                        width='33'
                        style={{
                          margin: 2,
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    </label>
                  </Box>
                </Box>
              </div>
            </Box>
            <br />
            <div className='sm:col-span-3'>
              <label className='text-md block font-bold leading-6 text-gray-600'>
                Add Resource Title*
              </label>
              <div className='mt-2 py-2'>
                <input
                  placeholder='Enter Resource Title'
                  id='title'
                  type='text'
                  className='font-poppins  block w-full rounded-md border-0 bg-white py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                  value={
                    inputRes
                      ? inputRes?.res_title || ''
                      : updateRes?.res_title || ''
                  }
                  onChange={(e) =>
                    inputRes
                      ? setInputRes({
                          ...inputRes,
                          res_title: e.target.value,
                        })
                      : setUpdateRes({
                          ...updateRes,
                          res_title: e.target.value,
                        })
                  }
                />
              </div>
              <label className=' text-md block py-2 font-bold leading-6 text-gray-600'>
                Add Description
              </label>
              <div className='mt-2'>
                <textarea
                  placeholder='Enter Resource Description'
                  id='desc'
                  rows='3'
                  className='font-poppins block w-full rounded-md border-0 bg-white py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  value={
                    inputRes
                      ? inputRes?.res_desc || ''
                      : updateRes?.res_desc || ''
                  }
                  onChange={(e) =>
                    inputRes
                      ? setInputRes({
                          ...inputRes,
                          res_desc: e.target.value,
                        })
                      : setUpdateRes({
                          ...updateRes,
                          res_desc: e.target.value,
                        })
                  }
                />
              </div>
              <div className='py-3 sm:col-span-3'>
                <label className=' text-md block py-1 font-bold leading-6  text-gray-600'>
                  Add Link*
                </label>
                <div className='mt-2'>
                  <input
                    placeholder='https://'
                    id='link'
                    type='text'
                    className='font-poppins  block w-full rounded-md border-0 bg-white py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                    value={
                      inputRes
                        ? inputRes?.res_link || ''
                        : updateRes?.res_link || ''
                    }
                    onChange={(e) =>
                      inputRes
                        ? setInputRes({
                            ...inputRes,
                            res_link: e.target.value,
                          })
                        : setUpdateRes({
                            ...updateRes,
                            res_link: e.target.value,
                          })
                    }
                  />
                </div>
              </div>
              <div className='py-2 sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Select Role*
                </label>
                <div className='mt-2'>
                  <CheckboxGroup
                    selectedValues={inputRes ? inputRes.roles : updateRes.role}
                    onChange={handleRole}
                  />
                </div>
              </div>

              <div className='py-4 sm:col-span-6'>
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Reorder Sort
                </label>
                <div className='mt-2'>
                  <select
                    style={{ height: '50px', width: '250px' }}
                    onChange={(e) =>
                      setInputRes({ ...inputRes, sorts: e.target.value })
                    }
                    value={inputRes ? inputRes?.sorts : updateRes?.sort}
                    className='font-poppins block w-full rounded-md border-0 bg-white px-2 py-3.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  >
                    <option value={'0'}>0</option>
                    <option value={'1'}>1</option>
                    <option value={'2'}>2</option>
                    <option value={'3'}>3</option>
                    <option value={'4'}>4</option>
                    <option value={'5'}>5</option>
                  </select>
                </div>
              </div>

              <div className='py-2 sm:col-span-3'>
                <label className=' text-md block font-bold leading-6  text-gray-600'>
                  Featured
                </label>
                <div className='flex justify-start  py-4'>
                  <Checkbox
                    className='py-0 px-0'
                    checked={inputRes?.res_featur}
                    onChange={handleCheckboxChange}
                    style={{ color: '#1788b9' }}
                  />
                  <div className='text-md px-2 text-gray-600'>Yes</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
      <br />
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Update Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The resource has been updated successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
