/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import '@fontsource/poppins';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Container from '@src/components/commons/containerPage.common';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/admin/resource`;

const options = [
  { value: 'Doctors', label: 'Doctors' },
  { value: 'Clinic Assistants', label: 'Clinic Assistants' },
  { value: 'Nurses', label: 'Nurses' },
  { value: 'personal.specialist', label: 'Hub-Specialist' },
  { value: 'personal.allied-healthcare', label: 'Hub-Allied Healthcare' },
  { value: 'org.healthcare', label: 'Hub-Organisations' },
  { value: 'personal.gp', label: 'Hub-GP Locum' },
  // { value: 'ALL', label: 'ALL' },
];

function CheckboxGroup({ selectedValues = [], onChange }) {
  return (
    <div className='text-md py-2 px-2 text-gray-600'>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                className='py-1'
                style={{ color: '#1788b9' }}
                checked={selectedValues.includes(option.value)}
                onChange={(e) => onChange(option.value, e.target.checked)}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </div>
  );
}

export default function CreateNewResource() {
  const { auth } = useAuth();
  const path = useLocation().pathname;
  const { resId } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [inputRes, setInputRes] = useState({
    title: '',
    desc: '',
    image: null,
    link: '',
    type: '',
    role: [],
    featur: '',
  });

  const handleCheckboxChange = (event) => {
    setInputRes({ ...inputRes, featur: event.target.checked });
  };

  const postResource = () => {
    const formData = new FormData();
    formData.append('res_title', inputRes.title);
    formData.append('res_link', inputRes.link);
    formData.append('res_desc', inputRes.desc);
    formData.append('res_image', inputRes.image);
    if (Array.isArray(inputRes.role) && inputRes.role.length > 0) {
      inputRes.role.forEach((role) => {
        formData.append('roles[]', role);
      });
    } else {
      console.warn('No roles provided or roles is not an array');
    }
    formData.append('res_featured', inputRes.featur);
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Resource created successfully:', response.data);
        setOpen(true);
      })
      .catch((error) => {
        console.log('Error creating resource:', error);
        toast.error('Failed to added resource', {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  const handleRole = (role, checked) => {
    if (checked) {
      setInputRes((prevState) => ({
        ...prevState,
        role: [...prevState.role, role],
      }));
    } else {
      setInputRes((prevState) => ({
        ...prevState,
        role: prevState.role.filter((r) => r !== role),
      }));
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setPreviewUrl(URL.createObjectURL(file));
    setInputRes((prevFormInput) => ({ ...prevFormInput, image: file }));
  };

  const handleBack = () => {
    navigate('/resources');
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    navigate('/resources');
  };

  return (
    <Container pathUrl={path}>
      <Box
        sx={{ width: '100%', fontFamily: 'Poppins', paddingLeft: 4 }}
        className='w-2/3'
      >
        <Button
          sx={{
            textTransform: 'none',
            paddingLeft: 0,
          }}
          className='py-6 px-6 font-bold text-purple-500'
          variant='text'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleBack}
        >
          Back
        </Button>
        <Box
          className='rounded-xl'
          sx={{
            height: 'auto',
            width: 'auto',
            backgroundColor: 'white',
            margin: 2,
          }}
        >
          <div className='flex justify-end px-8 py-8'>
            <Button
              // variant='outlined'
              className='text-md rounded-md bg-cyan-600 px-4 text-base font-bold text-white'
              sx={{ textTransform: 'none', width: '150px', height: '45px' }}
              onClick={() => {
                postResource();
                handleOpenModal();
              }}
            >
              Save
            </Button>
          </div>

          <div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-6 px-10 sm:grid-cols-6'>
            <div className='col-span-full'>
              <div className='sm:col-span-3'>
                <Box
                  sx={{
                    width: '200px',
                    height: '170px',
                  }}
                >
                  <label className='text-md block font-bold leading-6 text-gray-600'>
                    Add Resource Image (Optional)
                  </label>
                  <div className='flex justify-between'>
                    <Box
                      sx={{
                        width: '120px',
                        height: '120px',
                        position: 'relative',
                        overflow: 'hidden',
                      }}
                    >
                      <Box
                        sx={{
                          margin: 2,
                          width: '90px',
                          height: '90px',
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                        className='flex justify-center rounded-xl bg-gray-300'
                      >
                        <input
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileChange(e)}
                          name='files'
                          style={{ display: 'none' }}
                          id='upload-file-input'
                        />

                        {selectedFile || previewUrl || inputRes?.res_image ? (
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              position: 'relative',
                            }}
                          >
                            <img
                              src={previewUrl || inputRes.res_image}
                              alt=''
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                        ) : null}

                        <label htmlFor='upload-file-input'>
                          <Icon
                            icon='gala:add'
                            color='#8c8c8c'
                            width='33'
                            style={{
                              margin: 2,
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                            }}
                          />
                        </label>
                      </Box>
                    </Box>
                  </div>
                </Box>
                <br />
                <label className='text-md block font-bold leading-6 text-gray-600'>
                  Add Resource Title*
                </label>
                <div className='mt-2 py-2'>
                  <input
                    placeholder='Enter Resource Title'
                    id='title'
                    type='text'
                    className='font-poppins  block w-full rounded-md border-0 bg-white py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                    value={inputRes.title}
                    onChange={(e) =>
                      setInputRes({
                        ...inputRes,
                        title: e.target.value,
                      })
                    }
                  />
                </div>
                <label className='text-md block py-2 font-bold leading-6 text-gray-600'>
                  Add Description
                </label>
                <div className='mt-2'>
                  <textarea
                    placeholder='Enter Resource Description'
                    id='desc'
                    rows='3'
                    className='font-poppins block w-full rounded-md border-0 bg-white py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    value={inputRes.desc}
                    onChange={(e) =>
                      setInputRes({
                        ...inputRes,
                        desc: e.target.value,
                      })
                    }
                  />
                </div>
                <div className='py-5 sm:col-span-3'>
                  <label className=' text-md block font-bold leading-6  text-gray-600'>
                    Add Link*
                  </label>
                  <div className='mt-2'>
                    <input
                      placeholder='https://'
                      id='link'
                      type='text'
                      className='font-poppins  block w-full rounded-md border-0 bg-white py-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
                      value={inputRes.link}
                      onChange={(e) =>
                        setInputRes({
                          ...inputRes,
                          link: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className=' sm:col-span-6'>
                  <label className='text-md block font-bold leading-6 text-gray-600'>
                    Select Role*
                  </label>
                  <div className='mt-2'>
                    <CheckboxGroup
                      selectedValues={inputRes.role}
                      onChange={handleRole}
                    />
                  </div>
                </div>
                <br />
                <div className='sm:col-span-3'>
                  <label className=' text-md block font-bold leading-6  text-gray-600'>
                    Featured
                  </label>
                  <div className='flex justify-start py-4'>
                    <Checkbox
                      className='py-0 px-0'
                      checked={inputRes?.featur}
                      onChange={handleCheckboxChange}
                      style={{ color: '#1788b9' }}
                    />
                    <div className='text-md px-2 text-gray-600'>Yes</div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </Box>
      </Box>
      <br />
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Save Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The resource has been created successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
