/* eslint-disable import/no-extraneous-dependencies */
import { getGeneratedCertificates } from '@api/GenerateCertificate';
import { useAuth } from '@contexts/authContext';
import '@fontsource/poppins';
import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Container from '@src/components/commons/containerPage.common';
import Toast, { ERROR, SUCCESS } from '@src/components/toast';
import {
  capitalizeWords,
  copyTextToClipboard,
  mapToCertificateData,
  noop,
  openLinkInPopUpWindow,
  uuid,
} from '@utils';

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function ListCertificate({
  open,
  type,
  data,
  handleClick,
  handleOpenCertificate,
  handleCopyCertificateLink,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <List
      className='w-3/4 rounded-xl'
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        boxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        p: 0,
      }}
      component={Card}
    >
      <ListItemButton onClick={handleClick} sx={{ p: 2 }}>
        <ListItemText primary={type} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto'>
        <span className='flex w-3/4 flex-col items-start pr-20'>
          <TableContainer
            component={Paper}
            sx={{
              width: '960px',
              paddingBottom: '12px',
              paddingLeft: '12px',
              paddingRight: '12px',
              backgroundColor: '#ffffff',
              borderColor: 'ffffff',
              borderRadius: '0px',
              boxShadow: 'none',
            }}
          >
            <Table
              sx={{
                minWidth: 350,
                backgroundColor: '#ffffff',
                borderColor: 'ffffff',
              }}
              aria-label={`${type.toLowerCase()} table`}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{
                      padding: '12px 4px 4px 4px',
                      width: '250px',
                      border: 'gray',
                    }}
                  >
                    Date
                  </TableCell>
                  {/* <TableCell
                  className='text-xs font-bold text-gray-400'
                  style={{
                    width: '150px',
                    border: 'gray',
                    paddingLeft: 20,
                  }}
                >
                  Type
                </TableCell> */}
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{
                      padding: '12px 4px 4px 4px',
                      width: '250px',
                      border: 'gray',
                    }}
                  >
                    Topic
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{
                      padding: '12px 4px 4px 4px',
                      width: '250px',
                      border: 'gray',
                    }}
                  >
                    Recipient
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{
                      padding: '12px 4px 4px 4px',
                      width: '250px',
                      border: 'gray',
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(data) && data.length > 0 ? (
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row?.id || uuid()}
                        hover
                        tabIndex={-1}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 4, margin: 2 }}
                        >
                          {row?.date}
                        </TableCell>
                        {/* <TableCell
                        className='text-sm border-white font-bold text-gray-500'
                        style={{ padding: 8 }}
                      >
                        {row?.type}
                      </TableCell> */}
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 8 }}
                        >
                          {row?.topic}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 8 }}
                        >
                          {row?.recipient}
                        </TableCell>
                        <TableCell className='border-white'>
                          <Button
                            variant='contained'
                            className='text-xs font-bold text-purple-600'
                            sx={{
                              textTransform: 'none',
                              backgroundColor: '#FAF9FC',
                              '&:hover': {
                                backgroundColor: 'white',
                              },
                            }}
                            onClick={handleOpenCertificate(
                              row?.link || '',
                              `${row?.type || ''} - ${row?.recipient || ''}`,
                            )}
                          >
                            View
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            variant='contained'
                            className='text-xs font-bold text-green-600'
                            sx={{
                              textTransform: 'none',
                              backgroundColor: '#FAF9FC',
                              '&:hover': {
                                backgroundColor: 'white',
                              },
                            }}
                            onClick={handleCopyCertificateLink(
                              row?.shareLink || '',
                            )}
                          >
                            Copy Link
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      className='border border-white'
                      colSpan={6}
                      align='center'
                    >
                      <div className='font-poppins py-6 text-gray-500'>
                        No data
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </span>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Collapse>
    </List>
  );
}

export default function CertificatePage() {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [openToast, setOpenToast] = React.useState(false);
  const [toastData, setToastData] = React.useState({
    severity: ERROR,
    text: 'Error',
  });
  const [certificates, setCertificates] = React.useState([]);
  const [open, setOpen] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [anchorElAddNew, setAnchorElAddNew] = React.useState(null);
  const openAddNew = Boolean(anchorElAddNew);
  const isData =
    Array.isArray(certificates) && certificates.length > 0 && open !== null;

  const handelEditTemplate = async () => {
    navigate('/edit-certificate-template');
  };

  const handelAddNew = {
    click: (ev) => setAnchorElAddNew(ev.currentTarget),
    close: () => setAnchorElAddNew(null),
    certificate: () => navigate('/create-certificate'),
    template: () => navigate('/create-certificate-template'),
  };

  const handleClick = (key) => async () => {
    setOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleToast = {
    open: async () => {
      setOpenToast(true);
    },
    close: async () => {
      setOpenToast(false);
    },
    popUp: async (
      text,
      severity = SUCCESS,
      callback = noop,
      timeout = 1000,
    ) => {
      setToastData({
        severity,
        text,
      });
      setOpenToast(true);
      setTimeout(() => {
        setOpenToast(false);
        callback();
      }, timeout);
    },
  };

  const handleOpenCertificate = (link, title) => async () =>
    openLinkInPopUpWindow(link, title);

  const handleCopyCertificateLink = (link) => async () => {
    await copyTextToClipboard(link);
    await handleToast.popUp('Copied');
  };

  React.useEffect(() => {
    if (auth.isAuthenticated && auth.idToken) {
      (async () => {
        setIsLoading(true);
        const certsRes = await getGeneratedCertificates(auth.idToken);

        if (certsRes && certsRes.data && Array.isArray(certsRes.data)) {
          const certs = certsRes.data.map(({ type, data }) => ({
            type: capitalizeWords(type),
            data: Array.isArray(data) ? data.map(mapToCertificateData) : [],
          }));
          const types = {};

          certs.forEach((group, index) => {
            types[group.type] = Boolean(index === 0);
          });

          setOpen(types);
          setCertificates(certs);
          setIsLoading(false);
        }
      })();
    }
  }, [auth]);

  return (
    <Container pathUrl={path}>
      <Toast
        open={openToast}
        onClose={handleToast.close}
        onOpen={handleToast.open}
        severity={toastData.severity}
        text={toastData.text}
      />
      <Box
        sx={{ width: '100%', height: '60px' }}
        className='flex w-3/4 justify-end'
      >
        <Button
          onClick={handelEditTemplate}
          className='mr-3 h-[40px] rounded-xl border-none bg-gradient-to-l from-cyan-500 to-cyan-600 py-0 px-4 font-semibold normal-case text-white shadow hover:shadow-cyan-500'
          disableElevation
          startIcon={<EditNoteIcon />}
        >
          Edit Template
        </Button>
        <Button
          onClick={handelAddNew.click}
          className='h-[40px] rounded-xl border-none bg-gradient-to-l from-cyan-500 to-cyan-600 py-0 px-4 font-semibold normal-case text-white shadow hover:shadow-cyan-500'
          id='add-new-button'
          aria-controls={openAddNew ? 'add-new-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={openAddNew ? 'true' : undefined}
          disableElevation
          startIcon={<AddIcon />}
        >
          Add New
        </Button>
        <Menu
          id='add-new-menu'
          MenuListProps={{
            'aria-labelledby': 'add-new-button',
          }}
          anchorEl={anchorElAddNew}
          open={openAddNew}
          onClose={handelAddNew.close}
        >
          <MenuItem onClick={handelAddNew.certificate} disableRipple>
            Certificate
          </MenuItem>
          <MenuItem onClick={handelAddNew.template} disableRipple>
            Template
          </MenuItem>
        </Menu>
      </Box>
      <div className='text-md pb-3 font-semibold'>Certificates List</div>

      {isLoading ? (
        <div className='ml-2 italic text-gray-400'>Loading...</div>
      ) : null}

      {!isLoading ? (
        isData ? (
          <Stack spacing={2}>
            {certificates.map(({ type, data }) => (
              <ListCertificate
                key={uuid()}
                open={open[type]}
                type={type}
                data={data}
                handleClick={handleClick(type)}
                handleOpenCertificate={handleOpenCertificate}
                handleCopyCertificateLink={handleCopyCertificateLink}
              />
            ))}
          </Stack>
        ) : (
          <div className='ml-2 italic text-gray-400'>No data</div>
        )
      ) : null}
    </Container>
  );
}
