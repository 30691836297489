import { Box, Button, TextField, Typography } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/admin/skill-items`;

export default function EditSkillItemNurse({
  openModals,
  setOpenModals,
  fetchData,
}) {
  const { auth } = useAuth();
  const [data, setData] = useState({
    item: '',
    roles: ['nurse'],
  });

  React.useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        if (openModals) {
          const response = await axios.get(`${url}/${openModals}`, {
            headers: {
              Authorization: `Bearer ${auth.idToken}`,
            },
          });
          setData(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataAsync();
  }, [auth.idToken, openModals]);

  const handlePost = () => {
    const formData = {
      item: data?.item,
      roles: data?.roles,
    };

    axios
      .put(`${url}/${openModals}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Item updated successfully:', response);
        setData({ ...data });
        fetchData();
        toast.success('Item updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={openModals}
      onClose={() => setOpenModals(false)}
    >
      <DialogTitle>Edit item</DialogTitle>
      <br />

      <DialogContent>
        <div className='flex justify-between'>
          <Box flex={1} mr={1}>
            <Typography fontFamily='Poppins'>Name*</Typography>
            <TextField
              fullWidth
              size='medium'
              variant='outlined'
              placeholder='Enter name'
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                },
                mt: '5px',
              }}
              value={data?.item}
              onChange={(e) =>
                setData({
                  ...data,
                  item: e.target.value,
                })
              }
            />
            <br />
            <br />
          </Box>
          <br />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setOpenModals(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          color='primary'
          onClick={() => handlePost()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
