/* eslint-disable jsx-a11y/anchor-is-valid */
import '@fontsource/poppins';
// eslint-disable-next-line import/no-extraneous-dependencies
import Box from '@mui/material/Box';
import DoctorBCLS from '@src/components/forms/doctorBCLS.form';
import DoctorMalpracticeCert from '@src/components/forms/doctorMalpracticeCert.form';
import PersonalDocument from '@src/components/forms/personalDocument.form';
import PractisingInformationDoctor from '@src/components/forms/practisingCertDoctor.form';
import ProofResidence from '@src/components/forms/proofResidence.form';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}`;

export default function DoctorCertificate() {
  const { auth } = useAuth();
  const { userId } = useParams();

  const [getUser, setGetUser] = useState({
    name: '',
    gender: '',
    nationality: '',
    nric_fin: '',
    date: '',
    residential_status: '',
    address: '',
    referal: '',
  });

  useEffect(() => {
    if (auth.idToken) {
      axios
        .get(`${url}/personal-informations/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetUser(response.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, userId]);

  const [getDocument, setGetDocument] = useState({
    _id: '',
    user_id: '',
    file: '',
    name: '',
    from: '',
    to: '',
  });

  useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/admin/users/personal-document/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetDocument(response.data.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, userId]);

  const [getInfo, setGetInfo] = useState({
    _id: '',
    user_id: '',
    registration_number: '',
    type_registration: '',
    from: '',
    to: '',
  });

  useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/practicing-informations/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetInfo(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, userId]);

  const handlePutDocument = () => {
    const formData = {
      user_id: getUser._id,
      // front_id_card: getDocument.front_id_card,
      // back_id_card: getDocument.back_id_card,
      // proof_of_residence: getDocument.proof_of_residence,
      practicing_certificate: getDocument.practicing_certificate,
    };

    // console.log('data', formData);

    axios
      .post(`${url}/admin/users/practicing-certificates/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account updated successfully:', response.data);
        setGetDocument({ ...getDocument });
        toast.success('Certificate updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 3000,
        });
      });
  };

  const handlePutInfo = () => {
    const formData = {
      user_id: getUser._id,
      registration_number: getInfo.registration_number,
      type_registration: getInfo.type_registration,
      valid_from: getInfo.from,
      valid_until: getInfo.to,
    };

    // console.log('data', formData);

    axios
      .post(`${url}/admin/practicing-informations/${userId}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account updated successfully:', response.data);
        setGetInfo({ ...getInfo });
        // toast.success('Certificate updated successfully', {
        //   position: 'top-center',
        //   autoClose: 2000,
        // });
      })
      .catch((error) => {
        console.log('Error', error.message);
        // toast.error('The dates are required!', {
        //   position: 'top-center',
        //   autoClose: 3000,
        // });
      });
  };

  const [getMandatory, setGetMandatory] = useState({
    _id: '',
    user_id: '',
    certificate: '',
    from: '',
    to: '',
  });

  useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/admin/mandatory-certificates/${userId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetMandatory(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
      // console.log(getMandatory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, userId]);

  const [getItem, setGetItem] = useState();
  const [role, setRoles] = useState('doctor');
  useEffect(() => {
    if (auth?.idToken) {
      axios
        .get(`${url}/admin/mandatory-certificate-items?role=${role}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setGetItem(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
      console.log(getItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, userId]);

  const [getBcls, setGetBcls] = useState({
    file: '',
    certificate: '',
    from: '',
    to: '',
  });
  const handlePutBcls = () => {
    const formData = {
      file: getBcls.file,
      certificate: getItem[1]._id,
      validity_start: getBcls.from,
      validity_end: getBcls.to,
    };

    console.log('data', formData);

    axios
      .post(`${url}/admin/mandatory-certificates/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account updated successfully:', response.data);
        setGetMandatory({ ...getMandatory });
        toast.success('Certificate updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error('The date & file are required', {
          position: 'top-center',
          autoClose: 3000,
        });
      });
  };

  const [getMalpractice, setGetMalpractice] = useState({
    file: '',
    certificate: '',
    from: '',
    to: '',
  });
  const handlePutMalpractice = () => {
    const formData = {
      file: getMalpractice.file,
      certificate: getItem[2]._id,
      validity_start: getMalpractice.from,
      validity_end: getMalpractice.to,
    };

    console.log('data', formData);

    axios
      .post(`${url}/admin/mandatory-certificates/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Account updated successfully:', response.data);
        setGetMandatory({ ...getMandatory });
        toast.success('Certificate updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log('Error', error.message);
        toast.error('The date & file are required', {
          position: 'top-center',
          autoClose: 3000,
        });
      });
  };

  return (
    <Box
      sx={{
        width: '980px',
        fontFamily: 'Poppins',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div className='grid grid-cols-2 py-4'>
        <div className='col-end-1'>
          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '660px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Practicing Certificate*
            </div>
            <PractisingInformationDoctor
              getInfo={getInfo}
              setGetInfo={setGetInfo}
              getMandatory={getMandatory[0]}
              setGetMandatory={setGetMandatory}
              setGetDocument={setGetDocument}
              handlePutDocument={handlePutDocument}
              handlePutInfo={handlePutInfo}
            />
          </Box>

          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '660px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              BCLS Certificate
            </div>
            <DoctorBCLS
              getBcls={getBcls}
              setGetBcls={setGetBcls}
              getMandatory={getMandatory[1]}
              setGetMandatory={setGetMandatory}
              handlePutBcls={handlePutBcls}
            />
          </Box>

          <Box
            className='rounded-xl'
            sx={{
              height: 'auto',
              width: '660px',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <div className='font-poppins py-4 px-4 text-lg font-bold'>
              Medical Malpractice Certificate
            </div>
            <DoctorMalpracticeCert
              getMalpractice={getMalpractice}
              setGetMalpractice={setGetMalpractice}
              getMandatory={getMandatory[2]}
              setGetMandatory={setGetMandatory}
              handlePutMalpractice={handlePutMalpractice}
            />
          </Box>
        </div>
      </div>
    </Box>
  );
}
