/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import * as React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ClinicListingCLA from './clinicListingCLA';
import ClinicListingDoctor from './clinicListingDoctor';
import ClinicListingNurse from './clinicListingNurse';

function TabPanel(props) {
  const { children, value, index, style = {} } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabLabel(index) {
  return {
    id: `simple-tab-${index}`,
    poppins: `simple-tabpanel-${index}`,
  };
}

export default function ClinicListingPage() {
  const path = useLocation().pathname;
  // const [value, setValue] = React.useState('one');
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const { clinicId } = useParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorElAddNew, setAnchorElAddNew] = React.useState(null);
  const openAddNew = Boolean(anchorElAddNew);
  const handelAddNew = {
    click: (ev) => setAnchorElAddNew(ev.currentTarget),
    close: () => setAnchorElAddNew(null),
    doctor: () => navigate(`/create-new-slot/${clinicId}`),
    nurse: () => navigate(`/create-new-slot-nurse/${clinicId}`),
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [selectedLocumId, setSelectedLocumId] = useState(null);
  const handleClick = (event, locumId) => {
    console.log('Clicked locum ID:', locumId);
    setAnchorEl(event.currentTarget);
    setSelectedLocumId(locumId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box className='w-3/4 px-2'>
        <Box display='flex' justifyContent='end'>
          <div className='px-6'>
            <Button
              onClick={handelAddNew.click}
              className='h-[35px] w-[auto] rounded-xl border-none bg-gradient-to-l from-cyan-500 to-cyan-600 py-0 px-4 text-xs font-semibold normal-case text-white shadow hover:shadow-cyan-500'
              id='add-new-button'
              aria-controls={openAddNew ? 'add-new-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openAddNew ? 'true' : undefined}
              disableElevation
            >
              Create New Past Slot
            </Button>
            <Menu
              id='add-new'
              MenuListProps={{
                'aria-labelledby': 'add-new-button',
              }}
              anchorEl={anchorElAddNew}
              open={openAddNew}
              onClose={handelAddNew.close}
            >
              <MenuItem onClick={handelAddNew.doctor} disableRipple>
                Doctor/CLA
              </MenuItem>
              <MenuItem onClick={handelAddNew.nurse} disableRipple>
                Nurse
              </MenuItem>
            </Menu>
          </div>
          {/* <Button className='h-[35px] w-[auto] rounded-xl border-none bg-gradient-to-l from-cyan-500 to-cyan-600 py-0 px-4 text-xs font-semibold normal-case text-white shadow hover:shadow-cyan-500'>
            Update price(s)
          </Button> */}
        </Box>
      </Box>
      <Box className='w-full py-5 px-2'>
        <Box
          width='75%'
          display='flex'
          justifyContent='space-between'
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          className=' border-gray-50'
        >
          <Tabs
            indicatorColor='white'
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            className='px-6'
          >
            <Tab
              sx={{ textTransform: 'none', width: '130px' }}
              label='Doctor'
              className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                value === 0 ? 'bg-cyan-600 text-white' : 'bg-white'
              }`}
              {...tabLabel(0)}
            />
            <Box sx={{ width: '12px' }} />
            <Tab
              sx={{ textTransform: 'none' }}
              label='Clinic Assistant'
              className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                value === 2 ? 'bg-cyan-600 text-white' : 'bg-white'
              }`}
              {...tabLabel(2)}
            />
            <Box sx={{ width: '12px' }} />
            <Tab
              label='Nurse'
              sx={{ textTransform: 'none', width: '130px' }}
              className={`rounded-md border border-gray-300 font-bold text-cyan-600 ${
                value === 4 ? 'bg-cyan-600 text-white' : 'bg-white'
              }`}
              {...tabLabel(4)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ClinicListingDoctor />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ClinicListingCLA />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ClinicListingNurse />
        </TabPanel>
      </Box>
    </div>
  );
}
