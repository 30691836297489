import { Box, Button, TextField, Typography } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/lookup/master-data/residential-status/create`;

export default function AddResidenStatus({
  openModal,
  setOpenModal,
  fetchData,
}) {
  const { auth } = useAuth();
  const [data, setData] = useState([]);

  const handlePost = () => {
    const formData = {
      code: data?.code,
      desc: data?.desc,
      label: data?.label,
      note: data?.note,
    };

    axios
      .post(`${url}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Item added successfully:', response);
        setData({ ...data });
        fetchData();
        toast.success('Item added successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  return (
    <Dialog
      maxWidth='lg'
      fullwidth
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <DialogTitle>Add new item</DialogTitle>
      <br />

      <DialogContent>
        <Box flex={1} mr={1}>
          <Typography fontFamily='Poppins'>Code*</Typography>
          <TextField
            fullWidth
            size='medium'
            variant='outlined'
            placeholder='Enter code'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              mt: '5px',
              width: '400px',
            }}
            onChange={(e) =>
              setData({
                ...data,
                code: e.target.value,
              })
            }
          />
          <br />
          <br />
        </Box>

        <Box flex={1} mr={1}>
          <Typography fontFamily='Poppins'>Description*</Typography>
          <TextField
            fullWidth
            size='medium'
            variant='outlined'
            placeholder='Enter desc'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              mt: '5px',
              width: '400px',
            }}
            onChange={(e) =>
              setData({
                ...data,
                desc: e.target.value,
              })
            }
          />
          <br />
          <br />
        </Box>

        <Box flex={1} mr={1}>
          <Typography fontFamily='Poppins'>Label*</Typography>
          <TextField
            fullWidth
            size='medium'
            variant='outlined'
            placeholder='Enter label'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              mt: '5px',
              width: '400px',
            }}
            onChange={(e) =>
              setData({
                ...data,
                label: e.target.value,
              })
            }
          />
          <br />
          <br />
        </Box>

        <Box flex={1} mr={1}>
          <Typography fontFamily='Poppins'>Note*</Typography>
          <TextField
            fullWidth
            size='medium'
            variant='outlined'
            placeholder='Enter note'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              mt: '5px',
              width: '400px',
            }}
            onChange={(e) =>
              setData({
                ...data,
                note: e.target.value,
              })
            }
          />
          <br />
          <br />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => setOpenModal(false)}
          color='secondary'
        >
          Done
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          color='primary'
          onClick={handlePost}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
