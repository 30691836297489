import { useAuth } from '@contexts/authContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import ButtonAction from '@src/components/buttons/action.button';
import AddNotifChannel from '@src/components/modals/addNotificationChannel';
import EditNotificationChannel from '@src/components/modals/editNotifChannel';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const url = `${process.env.REACT_APP_API_URL}/v2/admin/clinic/profile/settings/notification`;

export default function ClinicSetting() {
  const { auth } = useAuth();
  const { clinicId } = useParams();
  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState();

  const [notif, setNotif] = React.useState([]);

  const fetchData = () => {
    setLoading(true);
    if (auth.idToken) {
      axios
        .get(`${url}?clinic_id=${clinicId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setNotif(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, deleteStatus]);

  const handleCheck = (index) => {
    const formData = {
      channel_is_active: !notif[index].channel_is_active,
    };

    axios
      .put(`${url}/${notif[index]._id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setNotif((prevNotif) => {
          const updatedNotif = [...prevNotif];
          updatedNotif[index].channel_is_active =
            !prevNotif[index].channel_is_active;
          return updatedNotif;
        });
        fetchData();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleVerify = (index) => {
    const formData = {
      channel_is_verified: !notif[index].channel_is_verified,
    };

    axios
      .put(`${url}/${notif[index]._id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setNotif({ ...notif });
        fetchData();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${url}/${id}`, {
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
          'My-Custom-Header': 'foobar',
        },
      });
      console.log(response);
      setDeleteStatus('Delete successful');
      toast.success(`${response.data.message}`, {
        autoClose: 2000,
        position: 'top-center',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const showModal = (itemId) => {
    setOpenModal(itemId);
  };

  const [addModal, setAddModal] = useState(false);
  const showAddModal = () => {
    setAddModal(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{ width: '100%', height: '60px' }}
        className='flex w-2/3 justify-end px-16'
      >
        <ButtonAction
          onNavigate={() => {
            showAddModal();
          }}
          label='Add New Item'
        />
      </Box>
      <Box sx={{ width: '100%', margin: 1 }} className='w-1/2'>
        {loading ? (
          <div className='font-poppins py-6 px-10 text-gray-500'>
            Loading...
          </div>
        ) : (
          <div>
            {Array.isArray(notif) && notif.length > 0 ? (
              notif.map((data, index) => (
                <>
                  <Box
                    key={data?._id}
                    sx={{ width: '100%', height: '140px', margin: 2 }}
                    className='rounded-xl bg-white'
                  >
                    <div className='flex justify-between'>
                      <div className='text-md py-6 px-6 text-gray-500'>
                        {data?.channel_type}
                      </div>
                      <div className='flex justify-end py-6 px-16 text-xs text-gray-500'>
                        <div> Active</div>
                        <div className='px-11'> Verify</div>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp;
                      </div>
                    </div>
                    <Box sx={{ width: 'auto' }}>
                      <div className='flex justify-between px-4'>
                        <div className='px-4 py-2 text-gray-600'>
                          {data?.channel_value}
                        </div>

                        <div className='flex justify-between px-2'>
                          <Switch
                            checked={data.channel_is_active}
                            onChange={() => handleCheck(index)}
                            name={`checked-${index}`}
                            inputProps={{
                              'aria-label': `primary checkbox ${index}`,
                            }}
                          />
                          &nbsp; &nbsp;
                          <Switch
                            checked={data.channel_is_verified}
                            onChange={() => handleVerify(index)}
                            name={`checked-${index}`}
                            inputProps={{
                              'aria-label': `primary checkbox ${index}`,
                            }}
                          />
                          &nbsp; &nbsp;
                          <Button
                            sx={{ textTransform: 'none' }}
                            variant='outlined'
                            color='secondary'
                            className='rounded-md'
                            onClick={() => showModal(data?._id)}
                          >
                            Change
                          </Button>
                          &nbsp; &nbsp;
                          <Button
                            sx={{ textTransform: 'none' }}
                            variant='outlined'
                            color='error'
                            className='rounded-md'
                            onClick={() =>
                              showDeleteConfirmationDialog(data._id)
                            }
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </Box>
                    {showDeleteConfirmation && (
                      <Dialog
                        open={showDeleteConfirmation}
                        onClose={handleConfirmationDialogClose}
                      >
                        <DialogTitle>Confirm Delete</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Are you sure you want to delete this item?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleConfirmationDialogClose}
                            color='primary'
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={handleConfirmationDialogConfirm}
                            color='error'
                          >
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                    )}
                  </Box>
                </>
              ))
            ) : (
              <div className='font-poppins text-md px-6 py-8 text-gray-600'>
                no data
              </div>
            )}
          </div>
        )}

        <EditNotificationChannel
          showModal={showModal}
          openModal={openModal}
          fetchData={fetchData}
          setOpenModal={setOpenModal}
        />

        <AddNotifChannel
          addModal={addModal}
          fetchData={fetchData}
          setAddModal={setAddModal}
        />
      </Box>
    </>
  );
}
