import '@fontsource/poppins';
import { CheckBox } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CreateAdminClinicForm({
  createAdmin,
  setCreateAdmin,
  getClinic,
}) {
  const [previousEmail, setPreviousEmail] = useState('');

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setCreateAdmin({
      ...createAdmin,
      email: newEmail,
    });

    if (newEmail === previousEmail) {
      toast.error('Email cannot be the same as the previous one', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCheckboxChange = (event) => {
    setCreateAdmin({
      ...createAdmin,
      isChannelNotification: event.target.checked,
    });
  };

  return (
    <Box width='auto' sx={{ backgroundColor: 'white' }} p={3}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box flex={1} mr={1}>
          <Typography fontFamily='Poppins'>Choose Admin*</Typography>
          <Select
            fullWidth
            displayEmpty
            sx={{ mt: 1, mb: '16px', borderRadius: '8px' }}
            MenuProps={{
              disablePortal: true,
              PaperProps: {
                style: {
                  zIndex: 1302,
                },
              },
            }}
            value={createAdmin?.admin_category}
            onChange={(e) =>
              setCreateAdmin({
                ...createAdmin,
                admin_category: e.target.value,
              })
            }
          >
            <MenuItem disabled value=''>
              <em style={{ color: '#8C8C8C' }}>Select</em>
            </MenuItem>
            <MenuItem value='company'>Organisation Admin</MenuItem>
            <MenuItem value='clinic'>Branch Admin</MenuItem>
          </Select>
        </Box>
      </Box>
      {createAdmin?.admin_category === 'clinic' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Box flex={1} mr={1}>
            <Typography fontFamily='Poppins'>Choose Branch*</Typography>
            <Select
              fullWidth
              displayEmpty
              sx={{ mt: 1, mb: '16px', borderRadius: '8px' }}
              MenuProps={{
                disablePortal: true,
                PaperProps: {
                  style: {
                    zIndex: 1302,
                  },
                },
              }}
              value={createAdmin?.clinicId}
              onChange={(e) =>
                setCreateAdmin({
                  ...createAdmin,
                  clinicId: e.target.value,
                })
              }
            >
              <MenuItem disabled value=''>
                <em style={{ color: '#8C8C8C' }}>Select</em>
              </MenuItem>

              {Array.isArray(getClinic) &&
                getClinic.map(({ _id, clinicName }) => (
                  <MenuItem key={_id} value={_id}>
                    {clinicName}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box flex={1} mr={1}>
          <Typography fontFamily='Poppins' mt={1}>
            Contact person*
          </Typography>
          <TextField
            fullWidth
            size='medium'
            variant='outlined'
            placeholder='Enter name'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              mt: '5px',
            }}
            value={createAdmin?.name || ''}
            onChange={(e) =>
              setCreateAdmin({
                ...createAdmin,
                name: e.target.value,
              })
            }
          />
          <br />
          <br />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box flex={1} mr={1}>
          <Typography fontFamily='Poppins'>Email*</Typography>
          <TextField
            fullWidth
            size='medium'
            variant='outlined'
            placeholder='Enter email'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              mt: 1,
            }}
            value={createAdmin?.email}
            onChange={handleEmailChange}
          />
          <br />
          <br />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box flex={1} mr={1}>
          <Typography fontFamily='Poppins'>Phone number*</Typography>
          <TextField
            fullWidth
            size='medium'
            variant='outlined'
            placeholder='Enter phone number. e.g. +65'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              mt: 1,
            }}
            value={createAdmin?.phone_number}
            onChange={(e) =>
              setCreateAdmin({
                ...createAdmin,
                phone_number: e.target.value,
              })
            }
          />
          <br />
          <br />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box>
          <Typography fontFamily='Poppins'>Password for account*</Typography>
          <FormControl className='py-2'>
            <OutlinedInput
              fullWidth
              size='medium'
              sx={{ width: '540px' }}
              placeholder='Enter password'
              id='password'
              type={showPassword ? 'text' : 'password'}
              value={createAdmin?.password || ''}
              onChange={(e) =>
                setCreateAdmin({
                  ...createAdmin,
                  password: e.target.value,
                })
              }
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Box>
      <div className='flex justify-start  py-4'>
        <FormControlLabel
          control={
            <Checkbox
              checked={createAdmin?.isChannelNotification}
              onChange={handleCheckboxChange}
              style={{ color: '#1788b9' }}
            />
          }
          label={
            <Typography fontSize={14} fontFamily='Poppins'>
              Do you want to sync this email & number in the notification
              channel?
            </Typography>
          }
          style={{ marginLeft: 0 }}
        />
      </div>
    </Box>
  );
}
