import '@fontsource/poppins';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CreateAdminOrg({ createAdmin, setCreateAdmin }) {
  const [previousEmail, setPreviousEmail] = useState('');

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setCreateAdmin({
      ...createAdmin,
      email: newEmail,
    });

    if (newEmail === previousEmail) {
      toast.error('Email cannot be the same as the previous one', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box p={3}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box flex={1} mr={1}>
          <Typography fontFamily='Poppins' mt={1}>
            Contact person*
          </Typography>
          <TextField
            fullWidth
            size='medium'
            variant='outlined'
            placeholder='Enter name'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              mt: '5px',
            }}
            value={createAdmin?.name || ''}
            onChange={(e) =>
              setCreateAdmin({
                ...createAdmin,
                name: e.target.value,
              })
            }
          />
          <br />
          <br />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box flex={1} mr={1}>
          <Typography fontFamily='Poppins'>Email*</Typography>
          <TextField
            fullWidth
            size='medium'
            variant='outlined'
            placeholder='Enter email'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
              },
              mt: 1,
            }}
            value={createAdmin?.email}
            onChange={handleEmailChange}
          />
          <br />
          <br />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box>
          <Typography fontFamily='Poppins'>Password for account*</Typography>
          <FormControl className='py-2'>
            <OutlinedInput
              fullWidth
              size='medium'
              sx={{ width: '475px' }}
              placeholder='Enter password'
              id='password'
              type={showPassword ? 'text' : 'password'}
              value={createAdmin?.password || ''}
              onChange={(e) =>
                setCreateAdmin({
                  ...createAdmin,
                  password: e.target.value,
                })
              }
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}
