/* eslint-disable react/no-unknown-property */

/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const url = `${process.env.REACT_APP_API_URL}/admin/organizations`;

export default function TableOrganisationUnverified() {
  const navigate = useNavigate();
  const format_date = { year: 'numeric', month: 'short', day: 'numeric' };

  const [deleteStatus, setDeleteStatus] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const handleClickOrg = (orgId) => {
    navigate(`/verification-organisation/${orgId}`);
  };

  const [loading, setLoading] = useState();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [list, setList] = useState([]);
  const { auth } = useAuth();

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredList = list.filter((row) => {
    return row?.business_name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const [status, setStatus] = useState('created');

  useEffect(() => {
    if (auth?.idToken) {
      setLoading(true);
      axios
        .get(`${url}?status=${status}`, {
          headers: {
            Authorization: `Bearer ${auth?.idToken}`,
          },
        })
        .then((response) => {
          setList(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log('Error', error.message);
          setLoading(false);
        });
    }
  }, [auth, status, deleteStatus]);

  const { orgId } = useParams();
  const [createOrg, setCreateOrg] = React.useState({});

  React.useEffect(() => {
    if (auth.idToken && typeof orgId === 'string' && orgId.length === 24) {
      axios
        .get(`${url}/${orgId}`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          // console.log('CA:', response);
          setCreateOrg(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.patch(
        `${url}/${id}`,
        {
          status: 'deleted',
        },
        {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      console.log(response);
      setCreateOrg({ ...createOrg });
      setDeleteStatus('Delete successful');
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteConfirmationDialog = (id) => {
    setDeleteId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmationDialogClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmationDialogConfirm = () => {
    handleDelete(deleteId);
    setShowDeleteConfirmation(false);
  };
  return (
    <div>
      <Box sx={{ width: '100%' }} className='flex w-3/4 justify-end px-6 py-4'>
        <input
          placeholder='Search by name'
          value={searchQuery}
          onChange={handleSearch}
          className='font-poppins  block  rounded-xl border-0 py-3 px-2 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
          style={{ height: '20px', width: '200px' }}
        />
      </Box>
      {loading ? (
        <div className='font-poppins py-6 px-8 text-gray-500'>Loading...</div>
      ) : (
        <span className='flex w-3/4 flex-col items-start pr-20'>
          <TableContainer
            className='rounded-xl'
            component={Paper}
            style={{
              width: '100%',
              paddingBottom: '12px',
              paddingLeft: '12px',
              paddingRight: '12px',
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ width: '300px' }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 0, width: '130px' }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 0, width: '200px' }}
                  >
                    Company
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 0, width: '150px' }}
                  >
                    Registered At
                  </TableCell>
                  <TableCell
                    className='text-xs font-bold text-gray-400'
                    style={{ padding: 4, width: '200px' }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(filteredList) && filteredList.length > 0 ? (
                  filteredList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        hover
                        role='checkbox'
                        tabIndex={-1}
                        key={row.code}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component='th'
                          scope='row'
                          className='border-white text-sm font-bold text-gray-500'
                        >
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '12px',
                            }}
                          >
                            {/* <Avatar
                            alt='Profile'
                            // src='https://i.pravatar.cc/300'
                          /> */}
                            {row.business_name}
                          </span>
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {row.code}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {row.type_of_company}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          {row.createdAt ? (
                            <>
                              {new Date(row.createdAt).toLocaleDateString(
                                'au-AU',
                                format_date,
                              )}
                            </>
                          ) : (
                            'No date'
                          )}
                        </TableCell>
                        <TableCell
                          className='border-white text-sm font-bold text-gray-500'
                          style={{ padding: 0 }}
                        >
                          <div className='flex'>
                            <Button
                              variant='contained'
                              className='text-xs font-bold text-cyan-600'
                              sx={{
                                textTransform: 'none',
                                backgroundColor: '#FAF9FC',
                                '&:hover': {
                                  backgroundColor: 'white',
                                },
                              }}
                              onClick={() => handleClickOrg(row?._id)}
                            >
                              View
                            </Button>
                            &nbsp; &nbsp;
                            <Button
                              variant='contained'
                              className='text-xs font-bold text-red-500'
                              sx={{
                                textTransform: 'none',
                                backgroundColor: '#FAF9FC',
                                '&:hover': {
                                  backgroundColor: 'white',
                                },
                              }}
                              onClick={() =>
                                showDeleteConfirmationDialog(row?._id)
                              }
                            >
                              Delete
                            </Button>
                          </div>
                          {showDeleteConfirmation && (
                            <Dialog
                              open={showDeleteConfirmation}
                              onClose={handleConfirmationDialogClose}
                            >
                              <DialogTitle>Confirm Delete</DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  Are you sure you want to delete this event?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={handleConfirmationDialogClose}
                                  color='primary'
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={handleConfirmationDialogConfirm}
                                  color='error'
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      className='border border-white'
                      colSpan={6}
                      align='center'
                    >
                      <div className='font-poppins py-6 text-gray-500'>
                        No organisations listed
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box
              className='flex justify-end py-3'
              sx={{ width: '100%', fontFamily: 'Poppins' }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component='div'
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        </span>
      )}
      <style jsx>{`
        @media (max-width: 768px) {
          .flex {
            display: block;
          }
          .pr-20 {
            padding-right: 0;
          }
        }
        @media (min-width: 769px) {
          .flex {
            display: flex;
          }
          .pr-20 {
            padding-right: 20px;
          }
        }
      `}</style>
    </div>
  );
}
