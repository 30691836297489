// eslint-disable-next-line import/no-extraneous-dependencies
import '@fontsource/poppins';
import Button from '@mui/material/Button';
import LabTabs from '@src/components/commons/tabs.common';
import { uuid } from '@utils';

import { useLocation, useNavigate } from 'react-router-dom';

import UpdateLearningJourney from './updateLearningJourney';
import UpdateNewEventInformation from './updateNewEventInformation';

export default function LearningJourney() {
  const { pathname: state } = useLocation();
  const navigate = useNavigate();

  const handleBack = async () => {
    navigate(state?.prevLocation || -1);
  };

  const menuEvent = [
    {
      key: uuid(),
      idx: 0,
      title: 'History',
      component: (
        <div key={uuid()}>
          <UpdateLearningJourney />
        </div>
      ),
    },
    {
      key: uuid(),
      idx: 1,
      title: 'Add Event Information',
      component: (
        <div key={uuid()}>
          <UpdateNewEventInformation />
        </div>
      ),
    },
  ];

  return (
    <div className='py-8 px-2'>
      <Button
        sx={{
          textTransform: 'none',
          paddingLeft: 0,
        }}
        className='py-6 px-6 font-bold text-purple-500'
        variant='text'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleBack}
      >
        Back
      </Button>
      <div className='py-2' />
      <LabTabs menus={menuEvent} />
    </div>
  );
}
